import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  Form,
  Icon,
  Upload,
  Input,
  Row,
  Col,
  Button as BT,
  DatePicker,
  Spin,
  Modal,
  message,
  Select,
  Alert,
} from "antd";
import autobind from "autobind-decorator";
import moment from "moment";
import { image } from "../../../resource";
import {
  ip,
  POST,
  GET,
  RIZZ_GETUSERALL,
  UPLOAD,
  RIZZ_REGIS_WARRANTY,
  RIZZ_GET_BRANDMOBILE,
  RIZZ_GET_MODELMOBILE,
  RIZZ_GETDETIALBYBARCODE,
} from "../../../service";
import {
  SearchOutlined,
  LoadingOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  StopOutlined,
  BranchesOutlined,
} from "@ant-design/icons";
const { Option } = Select;
const fn = require("../../../functions/functions");

@Form.create({ name: "RegisterWarranty" })
@observer
class RegisterWarranty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      product_name: "",
      register_date: moment(new Date()),
      imageUrl: "",
      name: "",
      telephone: "",
      lineid_colect: "",
      warranty_month: 12,
      checkNick: false,
      error_hidden: true,
      line_error: "พบข้อมูลผู้ใช้",
      error_color: "red",
      arr_brandmobile: [],
      arr_modelmobile: [],
      brandMobileName: "",
      modelMobileName: "",
      brandMobileID: "",
      modelMobileID: "",
      productID: "",
      productTypeID: "",
      barcode: "",
      searchedBarcode: "",
      prepBarcode: "8859",
      alertMessage: {
        message: "กรุณาค้นหารหัส Barcode ก่อนบันทึกข้อมูล",
        status: "warning",
        open: false,
      },
      isConfirmShow: false,
      modalMessage: {
        regMsgTitle: "ยืนยันการลงทะเบียน",
        regMsg: "ท่านต้องการลงทะเบียนสินค้านี้ใช่หรือไม่",
        status: <WarningOutlined />,
        color: "#ffc107",
      },
    };
  }

  componentDidMount() {
    this.getBrandMobile();
  }

  handleChange = (info) => {
    console.log(info.file.originFileObj);
    fn.getBase64(info.file.originFileObj, (imageUrl) =>
      this.setState({
        fileProduct: info.file.originFileObj,
        imageUrl,
        loading: false,
      })
    );
  };
  @autobind
  async createWarranty() {
    try {
      this.setState({
        modalMessage: {
          ...this.state.modalMessage,
          open: false,
          regMsgTitle: "ยืนยันการลงทะเบียน",
          regMsg: <LoadingOutlined spin />,
          status: <WarningOutlined />,
        },
        isRegSuccess: false,
      });
      let dataInsert = new FormData();
      dataInsert.append(
        "productID",
        !!this.state.productID ? this.state.productID : ""
      );
      dataInsert.append(
        "productTypeID",
        !!this.state.productTypeID ? this.state.productTypeID : ""
      );
      dataInsert.append(
        "brandMobileName",
        !!this.state.brandMobileName ? this.state.brandMobileName : ""
      );
      dataInsert.append(
        "modelMobileName",
        !!this.state.modelMobileName ? this.state.modelMobileName : ""
      );
      dataInsert.append(
        "brandMobileID",
        !!this.state.brandMobileID ? this.state.brandMobileID : ""
      );
      dataInsert.append("modelMobileID", "");
      // dataInsert.append('modelMobileID', !!this.state.modelMobileID?this.state.modelMobileID:'');
      dataInsert.append(
        "customerName",
        !!this.state.name ? this.state.name : ""
      );
      dataInsert.append(
        "customerTelephone",
        !!this.state.telephone ? this.state.telephone : ""
      );
      dataInsert.append(
        "barcode",
        !!this.state.searchedBarcode ? this.state.searchedBarcode : ""
      );
      dataInsert.append("userID", "");
      dataInsert.append("lineID", "");
      dataInsert.append("lineName", "");
      dataInsert.append(
        "productCode",
        !!this.state.productCode ? this.state.productCode : ""
      );
      const r = await POST(RIZZ_REGIS_WARRANTY, dataInsert);
      if (r.success == true) {
        this.setState({
          modalMessage: {
            ...this.state.modalMessage,
            open: false,
            regMsgTitle: "ลงทะเบียนสำเร็จ",
            regMsg: `หมายเลขการลงทะเบียนสินค้า: ${r.result.warrantyNo}`,
            status: <CheckCircleOutlined />,
            color: "#28a745",
          },
          isRegSuccess: true,
        });
      } else {
        this.setState({
          modalMessage: {
            ...this.state.modalMessage,
            open: false,
            regMsgTitle: "ลงทะเบียนไม่สำเร็จ",
            regMsg: "เกิดข้อผิดพลาด ลงทะเบียนไม่สำเร็จ",
            status: <StopOutlined />,
            color: "#dc3545",
          },
          isRegSuccess: false,
        });
      }
    } catch (err) {
      this.setState({
        modalMessage: {
          ...this.state.modalMessage,
          open: false,
          regMsgTitle: "ลงทะเบียนไม่สำเร็จ",
          regMsg: "เกิดข้อผิดพลาด ลงทะเบียนไม่สำเร็จ (" + err.message + ")",
          status: <StopOutlined />,
          color: "#dc3545",
        },
        isRegSuccess: false,
      });
    }
  }

  @autobind
  async onChangeSelect(val, ref) {
    let r = ref.props.className.split(" ")[0];
    // console.log('ref:',ref)
    // console.log('el:',el)
    // console.log('arr_model:',this.state.arr_modelmobile)
    switch (r) {
      case "brand":
        this.setState({ modelMobileName: "" });
        let status = val.split("|")[0];
        let name = val.split("|")[1];
        this.getModelMobile(status);
        this.setState({ brandMobileName: name, brandMobileID: status });
        break;
      case "model":
        status = val.split("|")[0];
        name = val.split("|")[1];
        // console.log( 'val:',val)
        // console.log( 'status:',status)
        // console.log( 'name:',name)
        this.setState({ modelMobileName: name, modelMobileID: status });
        break;
    }
  }

  @autobind
  onChange(e, ref) {
    this.setState({
      [ref]: e,
    });

    if (ref === "line_id") {
      this.setState({
        lineid_colect: "",
        error_hidden: true,
      });
    }
    console.log(ref);
  }

  @autobind
  onKeyPressSearch(event) {
    if (event.key === "Enter") {
      // this.handleSubmit()
    }
  }

  @autobind
  clear() {
    this.setState({
      line_id: "",
      product_name: "",
      register_date: moment(new Date()),
      imageUrl: "",
      imageUrlSlip: "",
      name: "",
      telephone: "",
      lineid_colect: "",
      warranty_month: 12,
      error_hidden: true,
      modelMobileName: "",
      brandMobileName: "",
      modelMobileID: "",
      brandMobileID: "",
      barcode: "",
      searchedBarcode: "",
      productID: "",
      productTypeID: "",
      productImage: "",
      productName: "",
      productTypeName: "",
      alertMessage: {
        ...this.state.alertMessage,
        open: true,
        message: "กรุณาค้นหารหัส Barcode ก่อนบันทึกข้อมูล",
        status: "warning",
      },
    });
  }
  @autobind
  handleSubmit(e) {
    // e.preventDefault();
    // this.props.form.validateFields((err, values) => {
    //     if (!err) {
    if (
      this.state.name === "" ||
      this.state.name === null ||
      this.state.name === undefined
    ) {
      message.error("กรุณากรอกชื่อ นามสกุล");
    } else if (!fn.phonNumberValidate(this.state.telephone)) {
      message.error("กรุณากรอกเบอร์โทรให้ครบถ้วน");
    } else if (
      this.state.brandMobileName === "" ||
      this.state.brandMobileName === null ||
      this.state.brandMobileName === undefined
    ) {
      message.error("กรุณาเลือกยี่ห้อมือถือ");
    }
    // else if ( this.state.modelMobileName==='' || this.state.modelMobileName===null || this.state.modelMobileName===undefined ) {
    //     message.error('กรุณาเลือกรุ่นมือถือ');
    // }
    else if (
      this.state.searchedBarcode === "" ||
      this.state.searchedBarcode === null ||
      this.state.searchedBarcode === undefined
    ) {
      message.error("กรุณาค้นหาสินค้าด้วยรหัส BARCODE");
    } else {
      this.setState({ isConfirmShow: true });
    }
  }
  getBrandMobile = async () => {
    //rizz api
    try {
      const r = await GET(RIZZ_GET_BRANDMOBILE);
      if (r.success) {
        let brand = r.result.map((val, i) => {
          return {
            label: val.brandName,
            value: val.brandID + "|" + val.brandName,
          };
        });
        this.setState({ arr_brandmobile: brand, loading: false });
      } else {
        message.error("เกิดข้อผิดพลาด BrandMobile");
      }
    } catch (err) {
      message.error(`เกิดข้อผิดพลาด BrandMobile: ${err.message}`);
    }
  };

  getModelMobile = async (status) => {
    try {
      const r = await GET(RIZZ_GET_MODELMOBILE + status);
      if (r.success) {
        let model = r.result.map((val, i) => {
          return {
            label: val.modelName,
            value: val.modelID + "|" + val.modelName,
          };
        });
        this.setState({ arr_modelmobile: model }, () =>
          console.log("arr_modelmobile", this.state.arr_modelmobile)
        );
      } else {
        message.error("เกิดข้อผิดพลาด modelMobile");
      }
    } catch (err) {
      message.error(`เกิดข้อผิดพลาด modelMobile: ${err.message}`);
    }
  };
  handleChangeBarcode = (e) => {
    // console.log('e:',e.target.value)
    this.setState({ barcode: e.target.value }, () =>
      console.log("barcode:", this.state.barcode)
    );
  };
  searchDetailByBarcode = async (barcode) => {
    try {
      let fullbarcode = this.state.prepBarcode + barcode;
      this.setState({
        alertMessage: {
          ...this.state.alertMessage,
          open: true,
          message: <LoadingOutlined style={{ fontSize: 20 }} spin />,
          status: "loading",
        },
      });
      const res = await GET(RIZZ_GETDETIALBYBARCODE + fullbarcode);
      if (res.success) {
        console.log("res_detailBc:", res.result);
        this.setState({
          productName: res.result.productName,
          productTypeName: res.result.productTypeNameTH,
          productImage: res.result.productImage,
          searchedBarcode: fullbarcode,
          productID: res.result.productID,
          productTypeID: res.result.productTypeID,
          productCode: res.result.productCode,
        });
        this.setState({
          alertMessage: {
            ...this.state.alertMessage,
            open: true,
            message: "ค้นหาสำเร็จ",
            status: "success",
          },
        });
      } else {
        this.setState({
          alertMessage: {
            ...this.state.alertMessage,
            open: true,
            message: "ไม่พบข้อมูลการลงทะเบียน หรือเกิดข้อผิดพลาด",
            status: "error",
          },
        });
      }
    } catch (err) {
      this.setState({
        alertMessage: {
          ...this.state.alertMessage,
          open: true,
          message:
            "ไม่พบข้อมูลการลงทะเบียน หรือเกิดข้อผิดพลาด(" + err.message + ")",
          status: "error",
        },
      });
    }
  };

  @autobind
  async closeConfirm() {
    await this.setState({ isConfirmShow: false });
    this.setState({
      modalMessage: {
        ...this.state.modalMessage,
        open: false,
        regMsgTitle: "ยืนยันการลงทะเบียน",
        regMsg: "ท่านต้องการลงทะเบียนสินค้านี้ใช่หรือไม่",
        status: <WarningOutlined />,
        color: "#ffc107",
      },
      isRegSuccess: false,
    });
    this.clear();
  }

  render() {
    const {
      error_hidden,
      line_error,
      loading,
      product_name,
      register_date,
      imageUrl,
      name,
      line_id,
      telephone,
      imageUrlSlip,
      lineid_colect,
      lineid_colect_color,
      warranty_month,
    } = this.state;

    const uploadButton = (
      <div>
        <Icon
          style={{ width: "6cm", height: "7cm" }}
          type={this.state.loading ? "loading" : "plus"}
        />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    if (loading) {
      return (
        <div className="example" style={{ width: "100%" }}>
          <Icon
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              fontSize: "6vw",
            }}
            type={this.state.loading ? "loading" : "plus"}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Form>
          {/* <Form onSubmit={this.handleSubmit}> */}
          <div>
            <Row>
              <Col span={10}>
                <div
                  className={"border-claim"}
                  style={{ margin: 15, height: "60%" }}
                >
                  <div className={"column-claim-cr"}>
                    <h2>ลูกค้าลงทะเบียนรับประกัน</h2>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: -35,
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <div
                        className={"column-claim-cr"}
                        style={{ marginTop: 70, marginLeft: -10 }}
                      >
                        <font
                          style={{
                            fontSize: ".8vw",
                            color: this.state.error_color,
                          }}
                          hidden={error_hidden}
                        >
                          {line_error}
                        </font>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: -50,
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <div className={"column-claim-cr"}>
                        <Form.Item label="ชื่อ-นามสกุล" className="formLabel">
                          <Input
                            style={{ width: "95%", fontSize: ".8vw" }}
                            defaultValue={name}
                            value={name}
                            onChange={(e) =>
                              this.onChange(e.target.value, "name")
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <div className={"column-claim-cr input-number"}>
                        <Form.Item label="เบอร์โทรศัพท์" className="formLabel">
                          <Input
                            id="input-number"
                            style={{ width: "95%", fontSize: ".8vw" }}
                            defaultValue={telephone}
                            onInput={(e) => {
                              // e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,maxLength)
                              e.target.value = e.target.value
                                .toString()
                                .slice(0, 10);
                            }}
                            type="number"
                            value={telephone}
                            onChange={(e) =>
                              this.onChange(e.target.value, "telephone")
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: -50,
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <div className={"column-claim-cr"}>
                        <Form.Item label="ยี่ห้อมือถือ" className="formLabel">
                          <Select
                            style={{ width: "95%", fontSize: ".8vw" }}
                            onChange={(val, ref) =>
                              this.onChangeSelect(val, ref)
                            }
                            value={this.state.brandMobileName}
                          >
                            {this.state.arr_brandmobile.map((item) => {
                              return (
                                <Option
                                  key={item.label}
                                  value={item.value}
                                  className="brand ant-input"
                                >
                                  {item.label}
                                </Option>
                              );
                              // ***class ตัวแรก คือ key ที่จะไป onChageนะครับ ***
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <div className={"column-claim-cr"}>
                        {/* <Form.Item label="รุ่นมือถือ" className="formLabel" >
                                            <Select 
                                                style={{ width: '95%', fontSize: '.8vw' }} 
                                                onChange={(val,el)=>this.onChangeSelect(val,el)}
                                                value={this.state.modelMobileName}
                                            >
                                            {
                                            this.state.arr_modelmobile.map(item => {
                                                return <Option key={item.label} value={item.value} className="model ant-input">{item.label}</Option> 
                                                // ***class ตัวแรก คือ key ที่จะไป onChageนะครับ ***
                                            })
                                            }
                                            </Select>
                                        </Form.Item> */}
                        <Form.Item label="รุ่นมือถือ" className="formLabel">
                          <Input
                            style={{ width: "95%", fontSize: ".8vw" }}
                            value={this.state.modelMobileName}
                            onChange={(e) =>
                              this.onChange(e.target.value, "modelMobileName")
                            }
                            maxLength={20}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={14}>
                <div className={"border-claim"} style={{ margin: 15 }}>
                  <div className={"column-claim-cr"}>
                    <h2>สินค้าลงทะเบียนรับประกัน</h2>
                    <div>
                      <Row
                        style={{
                          padding: "20px 0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Col span={12} className="input-number">
                          <Row key="barcode">
                            {this.state.alertMessage.open ? (
                              this.state.alertMessage.status == "loading" ? (
                                <Alert
                                  style={{
                                    marginBottom: "20px",
                                    fontSize: ".8vw",
                                  }}
                                  message={this.state.alertMessage.message}
                                  type={this.state.alertMessage.status}
                                />
                              ) : this.state.alertMessage.status ==
                                "success" ? (
                                <Alert
                                  style={{
                                    marginBottom: "20px",
                                    fontSize: ".8vw",
                                  }}
                                  message={this.state.alertMessage.message}
                                  type={this.state.alertMessage.status}
                                  showIcon
                                />
                              ) : (
                                <Alert
                                  style={{
                                    marginBottom: "20px",
                                    fontSize: ".8vw",
                                  }}
                                  message={this.state.alertMessage.message}
                                  type={this.state.alertMessage.status}
                                  showIcon
                                />
                              )
                            ) : (
                              <Alert
                                style={{
                                  marginBottom: "20px",
                                  fontSize: ".8vw",
                                }}
                                message={this.state.alertMessage.message}
                                type={this.state.alertMessage.status}
                                showIcon
                              />
                            )}
                            <p className="label-editProduct">Barcode</p>
                            <Input
                              addonBefore={
                                <p className="label-barcode-editProduct">
                                  {this.state.prepBarcode}
                                </p>
                              }
                              addonAfter={
                                <button
                                  className="btn-search-editProduct"
                                  onClick={() =>
                                    this.searchDetailByBarcode(
                                      this.state.barcode
                                    )
                                  }
                                >
                                  <SearchOutlined />
                                </button>
                              }
                              className="input-barcode-editProduct"
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  .toString()
                                  .slice(0, 9);
                              }}
                              type="number"
                              placeholder="9 หลักสุดท้าย"
                              value={
                                this.state.barcode !== "" &&
                                this.state.barcode !== null &&
                                this.state.barcode !== undefined
                                  ? this.state.barcode
                                  : ""
                              }
                              onChange={this.handleChangeBarcode}
                            />
                          </Row>
                          <Row key="productName" style={{ padding: "10px 0" }}>
                            <p className="label-editProduct">ชื่อสินค้า</p>
                            <Input
                              className="input-editProduct"
                              value={this.state.productName}
                              disabled
                            />
                          </Row>
                          <Row
                            key="productTypeName"
                            style={{ padding: "10px 0" }}
                          >
                            <p className="label-editProduct">ประเภทของสินค้า</p>

                            <Input
                              className="input-editProduct"
                              value={this.state.productTypeName}
                              disabled
                            />
                          </Row>
                        </Col>
                        <Col key="image" span={12}>
                          <div className="img-editProduct">
                            <img
                              alt="product"
                              src={
                                this.state.productImage !== "" &&
                                this.state.productImage !== null &&
                                this.state.productImage !== undefined
                                  ? ip + "/" + this.state.productImage
                                  : image.why_logo
                              }
                            />
                          </div>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              alignItems: "flex-end",
                              justifyContent: "center",
                              margin: "0 15px",
                            }}
                          >
                            <BT
                              style={{
                                width: "10vw",
                                height: "20vw",
                                background: "#FFFFFF",
                              }}
                              onClick={() => this.clear()}
                            >
                              เคลียร์ข้อมูล
                            </BT>
                            <BT
                              onClick={() => this.handleSubmit()}
                              className="btn-why"
                            >
                              ลงทะเบียน
                            </BT>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
        <Modal
          title={
            <font style={{ fontSize: "22px" }}>
              {this.state.modalMessage.regMsgTitle}
              <span
                style={{
                  color: this.state.modalMessage.color,
                  marginLeft: "10px",
                }}
              >
                {this.state.modalMessage.status}
              </span>
            </font>
          }
          visible={this.state.isConfirmShow}
          onCancel={this.closeConfirm}
          centered
          footer={[
            <Row key="save">
              {!this.state.isRegSuccess ? (
                <React.Fragment>
                  <BT
                    key="submit"
                    style={{ backgroundColor: "#52c41a" }}
                    loading={loading}
                    onClick={() => this.createWarranty()}
                  >
                    ใช่
                  </BT>
                  ,
                  <BT key="back" onClick={this.closeConfirm}>
                    ไม่ใช่
                  </BT>
                </React.Fragment>
              ) : (
                <BT
                  onClick={this.closeConfirm}
                  style={{
                    width: "5vw",
                    height: "10vw",
                    background: "red",
                    color: "#FFFFFF",
                  }}
                >
                  ตกลง
                </BT>
              )}
            </Row>,
          ]}
        >
          <font style={{ fontSize: "18px" }}>
            {this.state.modalMessage.regMsg}
          </font>
        </Modal>
      </React.Fragment>
    );
  }
}
// Form.create({ name: 'dynamic_rule' })(RegisterWarranty);
export default RegisterWarranty;

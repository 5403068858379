import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { observer } from "mobx-react";
import Root from "./routes/Root";
import LoginScene from "./scene/LoginScene";
import BillShippingScene from "./scene/Why/Claim/BillShippingScene";

@observer
class App extends Component {
  render() {
    return (
      <Router>
        <Route path={"/login"} component={LoginScene} />
        <Route path={"/Why/billshipping"} component={BillShippingScene} />
        <Root />
      </Router>
    );
  }
}

export default App;

import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  Icon,
  Upload,
  Input,
  Row,
  Col,
  Button as BT,
  DatePicker,
  Select,
  Modal,
  Spin,
  Form,
  message,
  Radio,
  Alert,
} from "antd";
import autobind from "autobind-decorator";
import {
  ip,
  POST,
  GET,
  RIZZ_UPSERTCLAIM,
  UPLOAD,
  RIZZ_GET_DETAIL_BY_TEL,
  RIZZ_GET_OPTION_BREAK,
  RIZZ_CLAIM_PRODUCT,
} from "../../../service";
import moment from "moment";
import InputAddress from "react-thailand-address-autocomplete";
import { image } from "../../../resource";
import {
  LoadingOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  StopOutlined,
  RightCircleFilled,
} from "@ant-design/icons";
import socketIOClient from "socket.io-client";
const { Option } = Select;

const fn = require("./../../../functions/functions");

@Form.create({ name: "CreateClaimScene" })
@observer
class CreateClaimScene extends Component {
  constructor(props) {
    super(props);
    this.props.form.setFieldsValue({
      line_id: "",
      name: "",
      telephone: "",
      other: "",
    });

    this.state = {
      claimdate: moment(new Date()),
      loading: false,
      product_id: "",
      register_date: moment(new Date()),
      imageUrl: "",
      dataSource: [],
      lineid_colect: "",
      data: [],
      status: 0,
      note: "",
      _address: [],
      radio: 1,

      claimImage: "",
      imageUrl: "",
      customerName: "",
      customerTelephone: "",
      subdistrict: "",
      district: "",
      province: "",
      zipcode: "",
      breakdownID: "",
      claimDetail: "",
      productName: "",
      productImage: "",
      isConfirmShow: false,
      modalMessage: {
        regMsgTitle: "ยืนยันการเคลม",
        regMsg: "ท่านต้องการเคลมสินค้านี้ใช่หรือไม่",
        status: <WarningOutlined />,
        color: "#ffc107",
      },
      isTelSearched: false,
      warrantyImage: "",
      alertMessage: "กรุณากรอกเบอร์โทรศัพท์เป็นลำดับแรก",
    };
  }

  componentDidMount() {}

  @autobind
  async getDetailByTel() {
    try {
      message.loading();
      this.setState({
        customerName: "",
        district: "",
        subdistrict: "",
        province: "",
        zipcode: "",
        products: "",
        claimDetail: "",
        claimDate: "",
        productImage: "",
        address: "",
      });
      console.log("tel:", this.state.customerTelephone);
      const r = await POST(RIZZ_GET_DETAIL_BY_TEL, {
        customerTelephone: this.state.customerTelephone,
      });
      if (r.success) {
        console.log("res_tel:", r.result);
        await this.setState({
          claimImage: "",
          imageUrl: "",
          customerName: "",
          subdistrict: "",
          district: "",
          province: "",
          zipcode: "",
          breakdownID: "",
          claimDetail: "",
          productTypeID: "",
          warrantyNo: "",
          address: "",
          isTelSearched: false,
          alertMessage: "กรุณากรอกเบอร์โทรศัพท์เป็นลำดับแรก",
          arr_breakdown: "",
        });
        this.setState({
          customerName: r.result.customerName,
          district: r.result.district,
          userID: r.result.userID,
          subdistrict: r.result.subdistrict,
          province: r.result.province,
          zipcode: r.result.zipcode,
          products: r.result.products,
          claimDetail: r.result.claimDetail,
          address: r.result.address,
          warrantyImage: r.result.warrantyImage,
          isTelSearched: true,
          alertMessage: "ค้นหาข้อมูลสำเร็จ",
        });
        message.success("ค้นพบข้อมูลการลงทะเบียน");
      } else {
        console.log("res_telelse:", r);
      }
    } catch (err) {
      this.setState({
        isTelSearched: false,
        claimImage: "",
        imageUrl: "",
        customerName: "",
        subdistrict: "",
        district: "",
        province: "",
        zipcode: "",
        breakdownID: "",
        claimDetail: "",
        productTypeID: "",
        warrantyNo: "",
        address: "",
        isTelSearched: false,
        alertMessage: "กรุณากรอกเบอร์โทรศัพท์เป็นลำดับแรก",
        arr_breakdown: "",
      });
      message.error("ไม่่พบข้อมูลการลงทะเบียน จากหมายเลขโทรศัพท์นี้");
    }
  }

  @autobind
  async getOptionBreak() {
    try {
      const r = await GET(RIZZ_GET_OPTION_BREAK + this.state.productTypeID);
      if (r.success) {
        this.setState({ arr_breakdown: r.result });
      } else {
        message.error("เกิดข้อผิดพลาด");
      }
    } catch (err) {
      message.error(err.message);
    }
  }

  handleChange = (e) => {
    // console.log('file', info.file.originFileObj)
    // fn.getBase64(info.file.originFileObj, imageUrl =>
    //     this.setState({
    //         claimImage: info.file.originFileObj,
    //         imageUrl,
    //         loading: false,
    //     },()=> {
    //         // console.log('img_file:',this.state.claimImage)
    //         // console.log('img_imageUrl:',this.state.imageUrl)
    //     }),
    // );
    console.log("e", e);
    const imgFile = e.target.files[0];
    const imgurl = URL.createObjectURL(imgFile);
    this.setState({ imageUrl: imgurl, claimImage: imgFile }, () =>
      console.log("imgFile", this.state.claimImage)
    );
  };

  @autobind
  async onChange(e, ref) {
    await this.setState({ [ref]: e });
    switch (ref) {
      case "customerTelephone":
        if (e.length == 10) this.getDetailByTel();
        break;
      case "claimDetail":
        console.log("claimDetial_e:", e);
        if (
          this.state.productTypeID === 99 ||
          this.state.productTypeID === "99"
        ) {
          let val = e.trimLeft();
          this.setState({ breakdownID: "", claimDetail: val });
        } else {
          let id = e.split("|")[0];
          let datail = e.split("|")[1];
          this.setState({ breakdownID: id, claimDetail: datail });
        }
        break;
    }
  }

  onChangeSelect = (value) => {
    console.log(`selected ${value}`);
    let prodID = value.split("|")[0];
    let wrNo = value.split("|")[1];
    let prodName = value.split("|")[2];
    let prodImg = value.split("|")[3];
    this.setState(
      {
        productTypeID: prodID,
        warrantyNo: wrNo,
        productName: prodName,
        productImage: prodImg,
      },
      () => {
        console.log("productImage", this.state.productImage);
        this.getOptionBreak();
        this.setState({ breakdownID: "", claimDetail: "" });
      }
    );
    // const product = this.state.data.find(el => el.register_id === value)
    // console.log(product)
    // this.setState({
    //     product_name: product.product_type,
    //     product_id: product.register_id
    // })
  };
  onChangeAdd = (value) => {
    console.log(`selectedAddress ${value}`);
    const address = this.state._address.find((el) => el.addressId === value);
    const { other, district, province, zipcode, amphure, addressId } = address;
    this.setState({
      other: address.other,
      district: amphure,
      subdistrict: district,
      province,
      zipcode,
      addressId,
    });
    this.props.form.setFieldsValue({
      other,
      district: amphure,
      subdistrict: district,
      province,
      zipcode,
    });
  };
  onSearchProduct = (val) => {
    console.log("search:", val);
    this.setState({
      other: val,
    });
    this.props.form.setFieldsValue({ other: val });
  };
  @autobind
  onKeyPressSearch(event) {
    if (event.key === "Enter") {
      // this.handleSubmit()
    }
  }
  @autobind
  onSearch() {
    const { line_id } = this.state;
    const item = this.state.line.find((el) => el.userId === line_id);
    console.log(item);
    if (item) {
      this.setState({
        lineid_colect: "check-circle",
        lineid_colect_color: "certain-category-icon-colect",
      });
    } else {
      this.setState({
        lineid_colect: "close-circle",
        lineid_colect_color: "certain-category-icon-uncolect",
      });
    }
  }

  onSelectAddress = (fullAddress) => {
    const { subdistrict, district, province, zipcode } = fullAddress;
    this.props.form.setFieldsValue({
      district,
      subdistrict,
      province,
      zipcode,
    });
    this.setState({
      subdistrict,
      district,
      zipcode,
      province,
    });
  };
  @autobind
  onChangeAddress(e, ref) {
    console.log("e.name", [e.target.name]);
    console.log(ref, e.target.value);
    this.setState({ [ref]: e.target.value });
  }
  @autobind
  clear() {
    // this.props.form.setFieldsValue({
    //     claimImage: '',
    //     imageUrl: '',
    //     customerName: '',
    //     customerTelephone: '',
    //     subdistrict: '',
    //     district: '',
    //     province: '',
    //     zipcode: '',
    //     breakdownID: '',
    //     claimDetail: '',
    //     products: '',
    //     productTypeID: '',
    //     warrantyNo: '',
    //     address: '',
    //     isTelSearched: false,
    //     arr_breakdown: '',
    //     alertMessage: 'กรุณากรอกเบอร์โทรศัพท์เป็นลำดับแรก',
    // })
    this.setState({
      claimImage: "",
      imageUrl: "",
      customerName: "",
      customerTelephone: "",
      subdistrict: "",
      district: "",
      province: "",
      zipcode: "",
      breakdownID: "",
      claimDetail: "",
      productTypeID: "",
      warrantyNo: "",
      address: "",
      arr_breakdown: [],
      productName: "",
      products: "",
      isTelSearched: false,
      alertMessage: "กรุณากรอกเบอร์โทรศัพท์เป็นลำดับแรก",
    });
  }

  @autobind
  handleSubmit(e) {
    if (!fn.phonNumberValidate(this.state.customerTelephone)) {
      message.error("กรุณากรอกเบอร์โทรให้ครบถ้วน");
    } else if (
      this.state.customerName === "" ||
      this.state.customerName === null ||
      this.state.customerName === undefined
    ) {
      message.error("กรุณากรอกชื่อ นามสกุล");
    } else if (
      this.state.address === "" ||
      this.state.address === null ||
      this.state.address === undefined
    ) {
      message.error("กรุณากรอกที่อยู่");
    } else if (
      this.state.district === "" ||
      this.state.district === null ||
      this.state.district === undefined
    ) {
      message.error("กรุณาชื่อ เขต/อำเภอ");
    } else if (
      this.state.subdistrict === "" ||
      this.state.subdistrict === null ||
      this.state.subdistrict === undefined
    ) {
      message.error("กรุณาชื่อ แขวง/ตำบล");
    } else if (
      this.state.province === "" ||
      this.state.province === null ||
      this.state.province === undefined
    ) {
      message.error("กรุณากรอกชื่อจังหวัด");
    } else if (
      this.state.zipcode === "" ||
      this.state.zipcode === null ||
      this.state.zipcode === undefined
    ) {
      message.error("กรุณากรอกเลขไปรษณีย์");
    } else if (
      this.state.warrantyNo === "" ||
      this.state.warrantyNo === null ||
      this.state.warrantyNo === undefined
    ) {
      message.error("กรุณาเลือกสินค้าที่ต้องการเคลม");
    } else if (
      this.state.claimDetail === "" ||
      this.state.claimDetail === null ||
      this.state.claimDetail === undefined
    ) {
      message.error("กรุณาเลือกอาการเสีย");
    } else if (
      this.state.claimImage === "" ||
      this.state.claimImage === null ||
      this.state.claimImage === undefined
    ) {
      message.error("กรุณาอัพโหลดรูปภาพสินค้า");
    } else {
      this.setState({ isConfirmShow: true });
    }
  }

  @autobind
  async createClaim() {
    // console.log('claim_breakdownID', this.state.breakdownID)
    // console.log('claim_claimDetail', this.state.claimDetail)
    // console.log('claim_claimImage', this.state.claimImage)
    // console.log('claim_warrantyNo', this.state.warrantyNo)
    // console.log('claim_zipcode', this.state.zipcode)
    // console.log('claim_province', this.state.province)
    // console.log('claim_district', this.state.district)
    // console.log('claim_subdistrict', this.state.subdistrict)
    // console.log('claim_address', this.state.address)
    // console.log('claim_email', !!this.state.email?this.state.email:'')
    // console.log('claim_userID',this.state.userID)

    try {
      this.setState({
        modalMessage: {
          ...this.state.modalMessage,
          open: false,
          regMsgTitle: "ยืนยันการเคลม",
          regMsg: <LoadingOutlined spin />,
          status: <WarningOutlined />,
        },
        isRegSuccess: false,
      });
      let dataClaim = new FormData();
      dataClaim.append(["breakdownID"], this.state.breakdownID);
      dataClaim.append(["claimDetail"], this.state.claimDetail);
      dataClaim.append(["claimImage"], this.state.claimImage);
      dataClaim.append(["warrantyNo"], this.state.warrantyNo);
      dataClaim.append(["zipcode"], this.state.zipcode);
      dataClaim.append(["province"], this.state.province);
      dataClaim.append(["district"], this.state.district);
      dataClaim.append(["subdistrict"], this.state.subdistrict);
      dataClaim.append(["address"], this.state.address);
      dataClaim.append(["email"], !!this.state.email ? this.state.email : "");
      dataClaim.append(["userID"], this.state.userID);
      const r = await POST(RIZZ_CLAIM_PRODUCT, dataClaim);
      if (r.success) {
        this.setState({
          modalMessage: {
            ...this.state.modalMessage,
            open: false,
            regMsgTitle: "เคลมสำเร็จ",
            regMsg: `หมายเลขการเคลมสินค้า: ${r.result.claimNo}`,
            status: <CheckCircleOutlined />,
            color: "#28a745",
          },
          isRegSuccess: true,
        });
        this.clear();
        this.sendToSocket();
      } else {
        this.setState({
          modalMessage: {
            ...this.state.modalMessage,
            open: false,
            regMsgTitle: "เคลมไม่สำเร็จ",
            regMsg: "เกิดข้อผิดพลาด เคลมไม่สำเร็จ",
            status: <StopOutlined />,
            color: "#dc3545",
          },
          isRegSuccess: false,
        });
        this.clear();
      }
    } catch (err) {
      this.clear();
      this.setState({
        modalMessage: {
          ...this.state.modalMessage,
          open: false,
          regMsgTitle: "เคลมไม่สำเร็จ",
          regMsg: "เกิดข้อผิดพลาด เคลมไม่สำเร็จ (" + err.message + ")",
          status: <StopOutlined />,
          color: "#dc3545",
        },
        isRegSuccess: false,
      });
    }
  }

  @autobind
  sendToSocket() {
    const socket = socketIOClient("https://dplus-service.com:1141");
    console.log("sendToSocket");
    socket.emit("afterApprove", "success");
  }

  @autobind
  async closeConfirm() {
    await this.setState({ isConfirmShow: false });
    this.setState({
      modalMessage: {
        ...this.state.modalMessage,
        open: false,
        regMsgTitle: "ยืนยันการลงทะเบียน",
        regMsg: "ท่านต้องการลงทะเบียนสินค้านี้ใช่หรือไม่",
        status: <WarningOutlined />,
        color: "#ffc107",
      },
      isRegSuccess: false,
      isTelSearched: true,
      alertMessage: "ค้นหาข้อมูลสำเร็จ",
    });
    this.clear();
  }

  render() {
    const {
      customerTelephone,
      customerName,
      district,
      subdistrict,
      province,
      zipcode,
      address,
      products,
      claimDetail,
      imageUrl,
      email,
      loading,
    } = this.state;
    const uploadButton = (
      <div>
        <Icon
          style={{ width: "6cm", height: "7cm" }}
          type={this.state.loading ? "loading" : "plus"}
        />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    // if (loading) {
    //     return <div className="example">
    //         <Spin />
    //     </div>
    // }

    return (
      // <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit}>
      <Form>
        <div className="form-claim">
          <Row>
            <Col span={14}>
              <div className={"border-claim"}>
                <h2>ยื่นเรื่องเคลม</h2>
                <div className={"box-alert"}>
                  {this.state.isTelSearched ? (
                    <Alert
                      message={this.state.alertMessage}
                      type="success"
                      showIcon
                    />
                  ) : (
                    <Alert
                      message={this.state.alertMessage}
                      type="warning"
                      showIcon
                    />
                  )}
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr cr-mg-5 input-number"}>
                      <Form.Item label="เบอร์โทรศัพท์" className="formLabel">
                        <Input
                          style={{ width: "100%" }}
                          onInput={(e) => {
                            // e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,maxLength)
                            e.target.value = e.target.value
                              .toString()
                              .slice(0, 10);
                          }}
                          type="number"
                          value={customerTelephone}
                          onChange={(e) =>
                            this.onChange(e.target.value, "customerTelephone")
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr cr-mg-5"}>
                      <Form.Item label="ชื่อ - นามสกุล" className="formLabel">
                        <Input
                          style={{ width: "95%" }}
                          value={customerName}
                          disabled
                          onChange={(e) =>
                            this.onChange(e.target.value, "customerName")
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className={"column-claim-cr cr-mg-5"}>
                  <Form.Item
                    label="ที่อยู่"
                    className="formLabel"
                    style={{ marginRight: "2em" }}
                  >
                    <Input
                      style={{ width: "100%" }}
                      value={address}
                      onChange={(e) => this.onChange(e.target.value, "address")}
                    />
                  </Form.Item>
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr cr-mg-5"}>
                      <Form.Item label="ตำบล" className="formLabelAddress">
                        <InputAddress
                          style={{ width: "100%" }}
                          address="subdistrict"
                          value={subdistrict}
                          onChange={(e) =>
                            this.onChangeAddress(e, "subdistrict")
                          }
                          onSelect={this.onSelectAddress}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr cr-mg-5"}>
                      <Form.Item label="อำเภอ" className="formLabelAddress">
                        <InputAddress
                          style={{ width: "100%" }}
                          address="district"
                          value={district}
                          onChange={(e) => this.onChangeAddress(e, "district")}
                          onSelect={this.onSelectAddress}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr cr-mg-5"}>
                      <Form.Item label="จังหวัด" className="formLabelAddress">
                        <InputAddress
                          className={"outline-focus"}
                          style={{ width: "100%" }}
                          address="province"
                          value={province}
                          onChange={(e) => this.onChangeAddress(e, "province")}
                          onSelect={this.onSelectAddress}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div className={"column-claim-cr cr-mg-5"}>
                      <Form.Item
                        label="รหัสไปรษณีย์"
                        className="formLabelAddress"
                      >
                        <InputAddress
                          style={{ width: "100%" }}
                          address="zipcode"
                          value={zipcode}
                          onChange={(e) => this.onChangeAddress(e, "zipcode")}
                          onSelect={this.onSelectAddress}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div
                  // className={"column-claim-cr cr-mg-5"}
                  style={{ marginRight: "2em", marginLeft: "2em" }}
                >
                  <Form.Item label="email (ถ้ามี)" className="formLabel">
                    <Input
                      style={{ width: "100%" }}
                      value={email}
                      onChange={(e) => this.onChange(e.target.value, "email")}
                    />
                  </Form.Item>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      margin: 20,
                      justifyContent: "flex-end",
                    }}
                  >
                    <BT
                      style={{
                        width: "10vw",
                        height: "20vw",
                        background: "#FFFFFF",
                        color: "Black",
                      }}
                      onClick={() => this.clear()}
                    >
                      เคลียร์ข้อมูล
                    </BT>
                    <BT
                      onClick={() => this.handleSubmit()}
                      style={{
                        width: "10vw",
                        height: "20vw",
                        background: "#faad14",
                        color: "#FFFFFF",
                      }}
                    >
                      ยื่นเรื่อง
                    </BT>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={10}>
              <div className={"border-claim"} style={{ marginLeft: 15 }}>
                <div
                  className={"column-claim-cr cr-mg-5"}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h2>ข้อมูลการเคลม</h2>
                </div>
                <div className={"column-claim-cr cr-mg-5"}>
                  <Form.Item
                    label="เลือกสินค้าที่ต้องการเคลม"
                    className="formLabel"
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="เลือกสินค้าที่ต้องการเคลม"
                      optionFilterProp="children"
                      onChange={this.onChangeSelect}
                      value={
                        !!this.state.warrantyNo
                          ? `(${this.state.warrantyNo})` +
                            this.state.productName
                          : ""
                      }

                      //onSearch={this.onSearchProduct}
                      // filterOption={(input, option) =>
                      //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      // }
                    >
                      {products !== "" &&
                      products !== null &&
                      products !== undefined
                        ? products.map((item) => (
                            <Option
                              className="ant-input"
                              value={
                                item.productTypeID +
                                "|" +
                                item.warrantyNo +
                                "|" +
                                item.productName +
                                "|" +
                                (item.warrantyImage !== "" &&
                                item.warrantyImage !== null &&
                                item.warrantyImage !== undefined
                                  ? item.warrantyImage
                                  : item.productImage)
                              }
                            >
                              (เลขประกัน:&nbsp;{item.warrantyNo})&nbsp;
                              {item.productName}
                            </Option>
                          ))
                        : ""}
                    </Select>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      {this.state.warrantyImage !== "" &&
                      this.state.warrantyImage !== null &&
                      this.state.warrantyImage !== undefined ? (
                        <img
                          src={ip + "/" + this.state.warrantyImage}
                          alt="av"
                          style={{ maxHeight: "12vw" }}
                        />
                      ) : this.state.productImage !== "" &&
                        this.state.productImage !== null &&
                        this.state.productImage !== undefined ? (
                        <img
                          src={ip + "/" + this.state.productImage}
                          alt="av"
                          style={{ maxHeight: "12vw" }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div>
                                            <img 
                                                width="100" 
                                                src={this.state.productImage !== '' &&this.state.productImage !== null &&this.state.productImage !== undefined?
                                                    ip + '/' + this.state.productImage: image.why_logo}
                                                alt="av" 
                                                style={{ width: '1cm', height: '0.5cm' }} 
                                            />
                                        </div> */}
                  </Form.Item>
                </div>
                <div className={"column-claim-cr cr-mg-5"}>
                  <Form.Item label="อาการเสีย" className="formLabel">
                    {this.state.productTypeID === 99 ||
                    this.state.productTypeID === "99" ? (
                      <Input
                        style={{ width: "95%" }}
                        value={this.state.claimDetail}
                        onChange={(e) =>
                          this.onChange(e.target.value, "claimDetail")
                        }
                      />
                    ) : (
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="เลือกอาการเสีย"
                        optionFilterProp="children"
                        onChange={(e) => this.onChange(e, "claimDetail")}
                        value={this.state.claimDetail}
                      >
                        {this.state.arr_breakdown !== "" &&
                        this.state.arr_breakdown !== null &&
                        this.state.arr_breakdown !== undefined
                          ? this.state.arr_breakdown.map((item) => (
                              <Option
                                className="ant-input"
                                value={
                                  item.breakdownID + "|" + item.breakdownName
                                }
                              >
                                {item.breakdownName}
                              </Option>
                            ))
                          : ""}
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div
                  className={"column-claim-cr cr-mg-5"}
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Form.Item
                    label="รูปภาพสินค้า"
                    style={{ textAlign: "center" }}
                    className="formLabel"
                  >
                    <label for="image" className="label-upload">
                      <div className="span-upload">UPLOAD</div>
                      {imageUrl ? <img src={imageUrl} alt="new" /> : ""}
                      <Input
                        id="image"
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={this.handleChange}
                      />
                    </label>
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
          <Modal
            title={
              <font style={{ fontSize: "22px" }}>
                {this.state.modalMessage.regMsgTitle}
                <span
                  style={{
                    color: this.state.modalMessage.color,
                    marginLeft: "10px",
                  }}
                >
                  {this.state.modalMessage.status}
                </span>
              </font>
            }
            visible={this.state.isConfirmShow}
            onCancel={this.closeConfirm}
            centered
            footer={[
              <Row key="save">
                {!this.state.isRegSuccess ? (
                  <React.Fragment>
                    <BT
                      key="submit"
                      style={{ backgroundColor: "#52c41a" }}
                      loading={loading}
                      onClick={() => this.createClaim()}
                    >
                      ใช่
                    </BT>
                    <BT key="back" onClick={this.closeConfirm}>
                      ไม่ใช่
                    </BT>
                  </React.Fragment>
                ) : (
                  <BT
                    onClick={this.closeConfirm}
                    style={{
                      width: "5vw",
                      height: "10vw",
                      background: "red",
                      color: "#FFFFFF",
                    }}
                  >
                    ตกลง
                  </BT>
                )}
              </Row>,
            ]}
          >
            <fon style={{ fontSize: "18px" }}>
              {this.state.modalMessage.regMsg}
            </fon>
          </Modal>
        </div>
      </Form>
    );
  }
}

export default CreateClaimScene;

import React from "react";
import { Input as In, Row, Col, Select, Alert } from "antd";
import { Label } from "../styles/styled";
import { SearchOutlined } from "@ant-design/icons";
import { ip } from "../service";
import { image } from "../resource";
const { Option } = Select;

export const Input = ({
  placeholder,
  label,
  error,
  name,
  value,
  onChange = () => {},
  width = "30%",
  onKeyPress = (e) => {},
}) => {
  return (
    <Row style={{ flexDirection: "row", marginBottom: "10px" }}>
      <Label
        //    style={{ color: '#fff', width: '34%', textAlign: 'left' }}>
        style={{ color: "#24231D", width: "34%", textAlign: "left" }}
      >
        {placeholder}
      </Label>

      {name === "password" ? (
        <In.Password
          style={{ width }}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e)}
          onKeyPress={(e) => onKeyPress(e)}
        />
      ) : (
        <In
          style={{ width }}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e)}
          onKeyPress={(e) => onKeyPress(e)}
        />
      )}
      {error && <div>Error</div>}
    </Row>
  );
};
export const SearchEditBarcode = ({
  items,
  barcode,
  changeBarcode,
  searchBarcode,
}) => {
  console.log("item:", barcode);
  return (
    <React.Fragment>
      <p className="label-editProduct">{items.label}</p>
      <Col key={items.label} span={24} className="input-number">
        <In
          addonBefore={<p className="label-barcode-editProduct">8859</p>}
          addonAfter={
            <button
              className="btn-search-editProduct"
              onClick={() => searchBarcode(barcode)}
            >
              <SearchOutlined />
            </button>
          }
          className="input-barcode-editProduct"
          onInput={(e) => {
            e.target.value = e.target.value.toString().slice(0, 9);
          }}
          type="number"
          placeholder="9 หลักสุดท้าย"
          value={
            barcode !== "" && barcode !== null && barcode !== undefined
              ? barcode
              : ""
          }
          onChange={changeBarcode}
        />
      </Col>
    </React.Fragment>
  );
};
export const NameEditBarcode = ({ items, prodName }) => {
  return (
    <React.Fragment>
      <p className="label-editProduct">{items.label}</p>
      <Col key={items.label} span={24}>
        <In className="input-editProduct" value={prodName} disabled />
      </Col>
    </React.Fragment>
  );
};
export const TypeEditBarcode = ({ items, prodType }) => {
  return (
    <React.Fragment>
      <p className="label-editProduct">{items.label}</p>
      <Col key={items.label} span={24}>
        <In className="input-editProduct" value={prodType} disabled />
      </Col>
    </React.Fragment>
  );
};

export const BreakEditBarcode = ({
  items,
  prodBreak,
  prodArrBreak,
  onChangeBreak,
  success,
  alertBreak,
}) => {
  return (
    <React.Fragment>
      {!success ? (
        ""
      ) : alertBreak ? (
        <Alert
          style={{ marginBottom: "10px", fontSize: ".8vw" }}
          message="กรุณาเลือกอาการเสีย"
          type="warning"
          showIcon
        />
      ) : (
        <Alert
          style={{ marginBottom: "10px", fontSize: ".8vw" }}
          message="สามาถบันทึกข้อมูลได้"
          type="success"
          showIcon
        />
      )}
      <p className="label-editProduct">{items.label}</p>
      <Col key={items.label} span={24}>
        <Select
          showSearch
          style={{ width: "100%" }}
          value={prodBreak}
          onChange={onChangeBreak}
          disabled={!success}
        >
          {prodArrBreak !== "" &&
          prodArrBreak !== null &&
          prodArrBreak !== undefined
            ? prodArrBreak.map((item) => (
                <Option
                  className="ant-input"
                  value={item.breakdownID + "|" + item.breakdownName}
                >
                  {item.breakdownName}
                </Option>
              ))
            : ""}
        </Select>
      </Col>
    </React.Fragment>
  );
};

export const BreakEditBarcodeOther = ({
  items,
  prodBreak,
  onChangeBreakOther = () => {},
  success,
  alertBreak,
}) => {
  return (
    <React.Fragment>
      {!success ? (
        ""
      ) : alertBreak ? (
        <Alert
          style={{ marginBottom: "10px", fontSize: ".8vw" }}
          message="กรุณากรอกอาการเสีย"
          type="warning"
          showIcon
        />
      ) : (
        <Alert
          style={{ marginBottom: "10px", fontSize: ".8vw" }}
          message="สามาถบันทึกข้อมูลได้"
          type="success"
          showIcon
        />
      )}
      <p className="label-editProduct">{items.label}</p>
      <In
        onChange={(e) => onChangeBreakOther(e.target.value)}
        value={prodBreak}
        disabled={!success}
      />
    </React.Fragment>
  );
};

export const ImageEditBarcode = ({
  items,
  prodImage,
  prodImageNoBarcode,
  prodName,
}) => {
  console.log("prodImage", prodImage);
  console.log("prodImageNoBarcode", prodImageNoBarcode);
  console.log("prodName", prodName);
  return (
    <React.Fragment>
      <Col key={items.label} span={24}>
        <div className="img-editProduct">
          {prodImage !== "" && prodImage !== null && prodImage !== undefined ? (
            <img
              alt="product"
              src={
                prodImage !== "" &&
                prodImage !== null &&
                prodImage !== undefined
                  ? ip + "/" + prodImage
                  : image.why_logo
              }
            />
          ) : (
            <img
              alt="product"
              src={
                prodImageNoBarcode !== "" &&
                prodImageNoBarcode !== null &&
                prodImageNoBarcode !== undefined &&
                (prodName == "" || prodName == null)
                  ? ip + "/" + prodImageNoBarcode
                  : image.why_logo
              }
            />
          )}
        </div>
      </Col>
    </React.Fragment>
  );
};

import React from "react";
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Modal,
  Select,
  Switch,
  Button,
  Upload,
  Spin,
} from "antd";
import { Label } from "../styles/styled";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { FORM_ADD_PRODUCT_WARRANTY } from "../resource/static";
import { UploadImage } from "./UploadImage";
import { color } from "../resource";
// import { Input } from "./Input";

export const ModalEMS = ({ obj = {}, onChange = () => {} }) => {
  return (
    <div>
      <Row>
        <Label>เลขพัสดุ</Label>
        <Input
          autoFocus
          placeholder={"กรุณาใส่เลข EMS"}
          style={{ width: "80%" }}
          value={obj["ems"]}
          onChange={(e) => onChange(e.target.value, "ems")}
        />
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Label>วันที่จัดส่ง</Label>
        <DatePicker
          style={{ width: "80%" }}
          placeholder={"กรุณาเลือกวันจัดส่ง"}
          value={obj["shipping_date"]}
          format={"DD/MM/YYYY"}
          onChange={(date) => onChange(date, "shipping_date")}
        />
      </Row>
    </div>
  );
};
export const ModalActive = ({ obj = {}, onChange = () => {} }) => {
  return (
    <div>
      <Row>
        <Label>รหัสรับประกัน</Label>
        <Input
          autoFocus
          placeholder={"กรุณาใส่เลขรหัสรับประกัน"}
          style={{ width: "80%" }}
          value={obj["unactive_code"]}
          onChange={(e) => onChange(e.target.value, "unactive_code")}
        />
      </Row>
    </div>
  );
};
export const ModalReject = ({ obj = {}, onChange = () => {} }) => {
  return (
    <div>
      <Row>
        <Label>เหตุผล</Label>
        <Input
          autoFocus
          placeholder={"กรุณาใส่เหตุผล"}
          style={{ width: "80%" }}
          value={obj["reject_detail"]}
          onChange={(e) => onChange(e.target.value, "reject_detail")}
        />
      </Row>
    </div>
  );
};
export const ModelSuccess = ({ title, detail }) => {
  Modal.success({
    title: { title },
    content: { detail },
  });
};
export const ModelError = ({ title, error }) => {
  Modal.error({
    title: { title },
    content: { error },
  });
};

export const ModalAddProductWarranty = ({
  openAdd,
  title,
  handleCancel,
  fileList,
  optionProductsType,
  checked,
  productType,
  img,
  handleChange = () => {},
  handleUpload = () => {},
  handleSwitch = () => {},
  confirm = () => {},
  handleSelect = () => {},
}) => {
  console.log({ openAdd });
  return (
    <Modal
      title={title}
      visible={openAdd}
      onCancel={handleCancel}
      footer={[
        <Button
          style={{ backgroundColor: "#898989", color: "#fff" }}
          onClick={handleCancel}
        >
          {"ยกเลิก"}
        </Button>,
        <Button
          style={{ backgroundColor: "#3fb604", color: "#fff" }}
          onClick={confirm}
        >
          {"บันทึก"}
        </Button>,
      ]}
    >
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} autocomplete="off">
        {FORM_ADD_PRODUCT_WARRANTY.map((props, i) => {
          const required = props.required ? (
            <span>
              <span style={{ color: "red" }}>* </span>
              {props.label}
            </span>
          ) : (
            <span>{props.label}</span>
          );
          if (props.type == "text") {
            return (
              <Form.Item label={required} name={`${props.name}`}>
                <Input
                  size="small"
                  name={props.name}
                  value={props.value}
                  onChange={(e) => handleChange(props.name, e.target.value)}
                />
              </Form.Item>
            );
          } else if (props.type == "select") {
            return (
              <Form.Item label={required}>
                <Select
                  onChange={handleSelect}
                  value={productType}
                  size="large"
                  placeholder="เลือกประเภทสินค้า"
                >
                  {optionProductsType.map((val) => {
                    return (
                      <Select.Option value={val.value}>
                        {val.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          } else if (props.type == "upload") {
            return (
              <Form.Item label={required}>
                {/* <Upload
                  listType="picture-card"
                  fileList={fileList}
                  //   fileList={fileList}
                  //   onPreview={this.handlePreview}
                  onChange={handleUpload}
                >
                  <UploadOutlined style={{ fontSize: "20px" }} />
                </Upload> */}
                <UploadImage img={img} handleUpload={handleUpload} />
              </Form.Item>
            );
          } else {
            return (
              <Form.Item label={required}>
                <Switch checked={checked} onChange={handleSwitch} />
              </Form.Item>
            );
          }
        })}
      </Form>
    </Modal>
  );
};

export const ModalEditProductWarranty = ({
  openEdit,
  dataEdit,
  title,
  handleCancel,
  optionProductsType,
  handleChange = () => {},
  handleUpload = () => {},
  handleSwitch = () => {},
  confirm = () => {},
  handleSelect = () => {},
}) => {
  console.log({ openEdit });
  return (
    <Modal
      title={title}
      visible={openEdit}
      onCancel={handleCancel}
      footer={[
        <Button
          style={{ backgroundColor: "#898989", color: "#fff" }}
          onClick={handleCancel}
        >
          {"ยกเลิก"}
        </Button>,
        <Button
          style={{ backgroundColor: "#3fb604", color: "#fff" }}
          onClick={confirm}
        >
          {"แก้ไข"}
        </Button>,
      ]}
    >
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} autocomplete="off">
        {dataEdit.map((props, i) => {
          const required = props.required ? (
            <span>
              <span style={{ color: "red" }}>* </span>
              {props.label}
            </span>
          ) : props.type == "hidden" ? null : (
            <span>{props.label}</span>
          );
          if (props.type == "text" || props.type == "hidden") {
            return (
              <Form.Item label={required}>
                <Input
                  type={props.type}
                  size="small"
                  name={props.name}
                  value={props.value}
                  onChange={(e) => handleChange(props.name, e.target.value)}
                />
              </Form.Item>
            );
          } else if (props.type == "select") {
            return (
              <Form.Item label={required}>
                <Select
                  onChange={handleSelect}
                  value={Number(props.value)}
                  size="large"
                  placeholder="เลือกประเภทสินค้า"
                >
                  {optionProductsType.map((val) => {
                    return (
                      <Select.Option value={val.value}>
                        {val.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          } else if (props.type == "upload") {
            return (
              <Form.Item label={required}>
                <UploadImage img={props.value} handleUpload={handleUpload} />
              </Form.Item>
            );
          } else {
            return (
              <Form.Item label={required}>
                <Switch checked={props.value} onChange={handleSwitch} />
              </Form.Item>
            );
          }
        })}
      </Form>
    </Modal>
  );
};

export const ModalConfirm = ({
  messagesAlert,
  visible,
  handleCancel = () => {},
  handleOnConfirm = () => {},
}) => {
  return (
    <Modal
      title={messagesAlert.title}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button
          style={{ backgroundColor: "#898989", color: "#fff" }}
          onClick={handleCancel}
        >
          {"ยกเลิก"}
        </Button>,
        <Button
          style={{ backgroundColor: "#1890FF", color: "#fff" }}
          onClick={handleOnConfirm}
        >
          {"ยืนยัน"}
        </Button>,
      ]}
    >
      <ExclamationCircleOutlined className="icon-confirm" />{" "}
      {messagesAlert.content}
    </Modal>
  );
};

export const ModalEditConfirm = ({
  messagesAlert,
  visible,
  handleCancel = () => {},
  handleOnConfirm = () => {},
}) => {
  return (
    <Modal
      title={messagesAlert.title}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button
          style={{ backgroundColor: "#898989", color: "#fff" }}
          onClick={handleCancel}
        >
          {"ยกเลิก"}
        </Button>,
        <Button
          style={{ backgroundColor: "#1890FF", color: "#fff" }}
          onClick={handleOnConfirm}
        >
          {"ยืนยัน"}
        </Button>,
      ]}
    >
      <ExclamationCircleOutlined className="icon-confirm" />{" "}
      {messagesAlert.content}
    </Modal>
  );
};

export const ModalSuccess = ({
  messagesAlert,
  success,
  handleCancel = () => {},
}) => {
  return (
    <Modal
      title={messagesAlert.title}
      visible={success}
      onCancel={handleCancel}
      footer={null}
    >
      {messagesAlert.status == "success" ? (
        <CheckCircleOutlined className="icon-success" />
      ) : (
        <CloseCircleOutlined className="icon-error" />
      )}

      {messagesAlert.content}
    </Modal>
  );
};

export const ModalLoading = ({ isLoading }) => {
  return (
    <Modal
      title={null}
      visible={isLoading}
      // onCancel={handleCancel}
      footer={null}
    >
      <div style={{ textAlign: "center" }}>
        <h2>{"กำลังบันทึกข้อมูล"}</h2>
        <Spin />
      </div>
    </Modal>
  );
};


export const ModalSendMessage = ({ data, onChange = () => {} }) => {
  return (
    <div>
      <Row>
        <Label>ข้อความ</Label>
        <Input.TextArea
          rows={4}
          defaultValue={data.lineMessage}
          value={data.lineMessage}
          onChange={(e) => onChange(e.target.value, "lineMessage")}
        />
      </Row>
    </div>
  );
};


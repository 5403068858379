import React, { Component } from "react";
import { observer } from "mobx-react";
import WrapperComponents from "../../../components/hoc/WrapperComponents";
import dayjs from "dayjs";
import moment from "moment";
import {
  Table,
  Button as BT,
  Modal,
  Input,
  message,
  DatePicker,
  Row,
  Col,
  Alert,
  Spin,
  Icon,
} from "antd";
import { columnTableClaim, color } from "../../../resource";
import autobind from "autobind-decorator";
import {
  ip,
  GET,
  RIZZ_GETCLAIM,
  POST,
  RIZZ_INSERT_EMS,
  RIZZ_GET_OPTION_BREAK,
  RIZZ_DETAILCLAIMBYID,
  RIZZ_UPDATESTATUS,
  RIZZ_GETDETIALBYBARCODE,
  RIZZ_UPDATEPRODUCTBYCLAIMNO,
  SEND_MESSAGE_LINE,
  RIZZ_EXPORT_CLAIM,
  UPDATE_CLAIM_NOTE
} from "../../../service";
import { encryptParameter } from "../../../functions/functions";
import { Button } from "../../../components/Button";
import { ModalEMS, ModalSendMessage } from "../../../components/Modal";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { FORM_EDIT_PRODUCT } from "../../../resource/static";
import {
  SearchEditBarcode,
  NameEditBarcode,
  TypeEditBarcode,
  ImageEditBarcode,
  BreakEditBarcode,
  BreakEditBarcodeOther,
} from "../../../components/Input";
import {
  EditFilled,
  LoadingOutlined,
  CheckOutlined,
  StopOutlined,
} from "@ant-design/icons";
import socketIOClient from "socket.io-client";

let defaultData = [];

@WrapperComponents()
@observer
class ClaimScene extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    date.setDate(date.getDate() - 5);

    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      loading: true,
      isLoadingData: true,
      visible: false,
      shipping_date: moment(),
      ems: "",
      data: [],
      searchText: "",
      claim_doc: "",
      isLoadingEMS: false,
      start_date: moment().clone().startOf("month").format("YYYY-MM-DD"),
      end_date: moment().clone().endOf("month").format("YYYY-MM-DD"),
      isOpen: false,
      isExpandable: true,
      obj_image: {},

      userLoginId: "",
      claimNumber: "",
      barcode: "",
      prepBarcode: "8859",
      searchedBarcode: "",
      disableEdit: true,
      alertMessage: {
        message: "",
        status: "",
        open: false,
      },
      productName: "",
      productTypeName: "",
      productImage: "",
      breakdownID: "",
      claimDetail: "",
      productTypeID: "",
      arr_breakdown: [],
      isSearchSuccess: false,
      productImageNoBarcode: "",
      confirmEditMessage: "ท่านยืนยันที่จะแก้ไขสินค้าใช่หรือไม่",
      dataSet: {},
      lineMessage: "",
      visibleModalNote: false,
      note:""
    };
  }

  async componentDidMount() {
    this.onFetchClaim();
    this.setState({ userLoginId: localStorage.getItem("userLoginId") }, () =>
      console.log("id:", this.state.userLoginId)
    );
    this.responseSocket();
  }

  responseSocket = () => {
    // const { endpoint, message } = this.state
    // const temp = message
    const socket = socketIOClient("https://dplus-service.com:1141");
    console.log("CouponDescriptionScene -> responseSocket -> responseSocket");
    socket.on("callApi", (messageNew) => {
      this.onFetchClaim();
    });
  };

  @autobind
  async onFetchClaim(
    date_start = this.state.start_date,
    date_end = this.state.end_date
  ) {
    try {
      let body = {
        claimDateFrom: date_start,
        claimDateTo: date_end,
      };
      const res = await POST(RIZZ_GETCLAIM, body);
      console.log(res);

      if (res.success) {
        // console.log('res_listclaim:',res.result)
        const data = res.result.map((el, i) => {
          const { product } = el;
          const diff = dayjs(this.changeFormatDate(el.endDate)).diff(
            dayjs(),
            "d"
          );
          if (el.status === 0) {
            console.log(el.claim_doc, product.product_name);
          }
          return {
            ...el,
            no: el.rowNo,
            key: el.claimNo,
            claim_id: el.claimNo,
            line_name: el.lineName,
            name: el.customerName,
            telephone: el.telephone,
            create_at: dayjs(el.claimDate).add(543, "y").format("DD/MM/YYYY"),
            productCode:el.productCode,
            product_name: el.productName,
            time_warranty: diff <= 0 ? 0 : diff,
            confirm_ems:
              el.claimStatus === 1 || el.claimStatus === 3
                ? el.tracking
                  ? // <font className="updateEms"  onClick={()=>this.setState({ visible: true, shipping_date: moment(), ems: '' })}>{el.tracking}</font>
                  el.tracking
                  : "กรอก EMS"
                : "",
            shipping_date: !!el.shippingDate
              ? dayjs(this.changeFormatDate(el.shippingDate))
                .add(543, "y")
                .format("DD/MM/YYYY")
              : "",
            note: el.note,
            edit_product:
              el.claimStatus === 1 || el.claimStatus === 4 ? (
                ""
              ) : (
                <BT
                  className="btn-editProduct"
                  style={{ backgroundColor: "#f0c800" }}
                  onClick={() =>
                    this.openEditProduct(
                      el.barcode,
                      el.productName,
                      el.productTypeName,
                      el.productImage,
                      el.warrantyImage,
                      el.claimNo,
                      el.claimDetail,
                      el.productTypeID
                    )
                  }
                >
                  <EditFilled />
                </BT>
              ),

            // ...el,
            // no: i + 1,
            // key: el.claim_doc,
            // name: customer.name,
            // telephone: customer.telephone,
            // product_name: product.product_name,
            // create_at: dayjs(el.create_at).add(543, 'y').format('DD/MM/YYYY'),
            // time_warranty: diff <= 0 ? 0 : diff,
            // shipping_date: shipping.shipping_date ? dayjs(shipping.shipping_date).add(543, 'y').format('DD/MM/YYYY') : '',
            // confirm_ems: (status === 1 || status === 2) ? shipping.ems ? shipping.ems : 'กรอก EMS' : '',
            // edit_product: (status === 1 || status === 2) ? '' : <BT className="btn-editProduct" onClick={()=>this.openEditProduct(el.claim_doc)}>
            //                 <EditFilled /></BT>,
            // late: true,
          };
        });
        defaultData = data;

        this.setState(
          {
            data: defaultData,
            isLoadingData: false,
            visible: false,
            ems: "",
            isLoadingEMS: false,
            loading: false,
          },
          () => this.onSearch()
        );
      }
    } catch (error) {
      console.log(error);
      this.setState({ isLoadingData: false });
    }
  }
  @autobind
  changeFormatDate(date) {
    if (date == "") {
      return "";
    } else {
      let dateTemp = date.split("-");
      return `${dateTemp[2]}-${dateTemp[1]}-${dateTemp[0]}`;
    }
  }

  @autobind
  async openEditProduct(
    barcode,
    prodName,
    prodType,
    prodImage,
    prodImageNoBarcode,
    claimNo,
    prodBreak,
    prodTypeID
  ) {
    const bc = barcode.substr(barcode.length - 9);
    this.setState(
      {
        isOpenEditProduct: true,
        barcode: bc,
        productName: prodName,
        productTypeName: prodType,
        productImage: prodImage,
        productImageNoBarcode: prodImageNoBarcode,
        claimNumber: claimNo,
        claimDetail: prodBreak,
        productTypeID: prodTypeID,
      },
      () => this.getOptionBreak(prodTypeID)
    );
    this.setState({
      alertMessage: {
        ...this.state.alertMessage,
        open: false,
        message: "",
        status: "",
      },
      disableEdit: true,
      confirmEditMessage: "ท่านยืนยันที่จะแก้ไขสินค้าใช่หรือไม่",
      breakdownID: "",
      prodTypeID: "",
      arr_breakdown: "",
      isSearchSuccess: false,
    });
  }
  @autobind
  async getOptionBreak(id) {
    try {
      console.log("id", id);
      const r = await GET(RIZZ_GET_OPTION_BREAK + id);
      if (r.success) {
        console.log("res_break:", r.result);
        this.setState({ arr_breakdown: r.result });
      } else {
        message.error("เกิดข้อผิดพลาด");
      }
    } catch (err) {
      message.error(err.message);
    }
  }

  @autobind
  async exportClaim() {
    try {
      let body = {
        claimDateFrom: this.state.start_date,
        claimDateTo: this.state.end_date,
      };
      const r = await POST(RIZZ_EXPORT_CLAIM, body);
      if (r.success) {
        message.success();
        console.log("export:", ip + r.result.pathFile);
        window.open(ip + r.result.pathFile);
        // message.error('เกิดข้อผิดพลาด');
      }
    } catch (err) {
      message.error(`เกิดข้อผิดพลาด (${err.message})`);
    }
  }

  @autobind
  async closeEditProduct() {
    await this.setState({ isOpenEditProduct: false });
    await this.setState({
      barcode: "",
      productName: "",
      productTypeName: "",
      productImage: "",
      productImageNoBarcode: "",
    });
  }

  @autobind
  openConfirmEdit() {
    this.setState({ isConfirmEdit: true });
  }

  @autobind
  closeConfirmEdit() {
    this.setState({ isConfirmEdit: false });
  }

  @autobind
  async confirmEditProduct() {
    // API for edit product
    try {
      this.setState({
        confirmEditMessage: <LoadingOutlined style={{ fontSize: 24 }} spin />,
      });
      const res = await POST(RIZZ_UPDATEPRODUCTBYCLAIMNO, {
        claimNo: this.state.claimNumber,
        barcode: this.state.searchedBarcode,
        breakdownID: this.state.breakdownID,
        claimDetail: this.state.claimDetail,
        userLogin: this.state.userLoginId,
      });
      if (res.success) {
        this.setState({
          confirmEditMessage: (
            <p style={{ margin: "0 10px", fontSize: "20px" }}>
              บันทึกข้อมูลสำเร็จ&nbsp;
              <CheckOutlined style={{ color: "#4bad1a" }} />
            </p>
          ),
        });
        this.onFetchClaim();
        setTimeout(() => {
          this.setState({ isOpenEditProduct: false, isConfirmEdit: false });
        }, 200);
      } else {
        this.setState({
          confirmEditMessage: (
            <p style={{ margin: "0 10px", fontSize: "20px" }}>
              บันทึกข้อมูลไม่สำเร็จ&nbsp;
              <StopOutlined style={{ color: "#EE220C" }} />
            </p>
          ),
        });
      }
    } catch (err) {
      this.setState({
        confirmEditMessage: (
          <p style={{ margin: "0 10px", fontSize: "20px" }}>
            บันทึกข้อมูลไม่สำเร็จ({err.message}) &nbsp;
            <StopOutlined style={{ color: "#EE220C" }} />
          </p>
        ),
      });
    }
  }

  handleChangeBarcode = (e) => {
    // console.log('e:',e.target.value)
    this.setState({ barcode: e.target.value }, () =>
      console.log("barcode:", this.state.barcode)
    );
  };

  searchDetailByBarcode = async (barcode) => {
    try {
      let fullbarcode = this.state.prepBarcode + barcode;
      this.setState({
        alertMessage: {
          ...this.state.alertMessage,
          open: true,
          message: <LoadingOutlined style={{ fontSize: 20 }} spin />,
          status: "loading",
        },
      });
      const res = await GET(RIZZ_GETDETIALBYBARCODE + fullbarcode);
      if (res.success) {
        console.log("res_detailBc:", res.result);
        this.setState(
          {
            productName: res.result.productName,
            productTypeName: res.result.productTypeNameTH,
            productImage: res.result.productImage,
            searchedBarcode: fullbarcode,
            prodTypeID: res.result.productTypeID,
            productTypeID: res.result.productTypeID,
            breakdownID: "",
            claimDetail: "",
            isSearchSuccess: true,
            disableEdit: true,
            productImageNoBarcode: "",
          },
          () => this.getOptionBreak(res.result.productTypeID)
        );
        this.setState({
          alertMessage: {
            ...this.state.alertMessage,
            open: true,
            message: "ค้นหาสำเร็จ",
            status: "success",
          },
        });
      } else {
        this.setState({
          alertMessage: {
            ...this.state.alertMessage,
            open: true,
            message: "ไม่พบข้อมูล หรือเกิดข้อผิดพลาด",
            status: "error",
          },
        });
      }
    } catch (err) {
      this.setState({
        alertMessage: {
          ...this.state.alertMessage,
          open: true,
          message: "ไม่พบข้อมูล หรือเกิดข้อผิดพลาด(" + err.message + ")",
          status: "error",
        },
      });
    }
  };

  start = () => {
    //latest
    let claim_doc = this.state.selectedRowKeys;
    const { data, selectedRowKeys } = this.state;
    let result = [];
    let notcolect = [];

    console.log(selectedRowKeys);
    result = selectedRowKeys.map((sl) => {
      console.log("ap_sl:", sl);
      console.log("ap_data:", data);
      let r = data.filter((el) => el.claimNo === sl);
      console.log("ap_r:", r);
      if (r[0]) {
        console.log("ap_r[0]:", r[0]);
        return {
          claimNo: r[0].claimNo,
          customerName: r[0].customerName,
          shiptoAddress: r[0].shiptoAddress,
          telephone: r[0].telephone,
          productName: r[0].productName,
          claimRemark: r[0].claimRemark ? r[0].claimRemark : "",
          claimStatus: r[0].claimStatus,
        };
      }
    });
    console.log("result_start:", result);

    result.map((el) => {
      console.log("ellll:", el);
      if (el.claimStatus === 2) {
        console.log("ap_barcode:", el.barcode);
        console.log("ap_claimStatus:", el.claimStatus);
        notcolect.push(el.claimNo);
      }
    });
    if (notcolect.length > 0) {
      message.error(
        `สถานะงานเคลม :${notcolect} ไม่อนุมัติสำหรับสถานะ "รอตรวจสอบ"`
      );
    } else {
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState(
          {
            selectedRowKeys: [],
            loading: false,
          },
          () =>
            this.props.history.push({
              pathname: "/why/billshipping",
              state: {
                id: encryptParameter({ claim_id: claim_doc }),
                item: result,
              },
            })
        );
      }, 1000);
    }
  };

  onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys:", selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  /**
   * ฟังก์ชั่นสำหรับวิ่งไปหน้า Detail Claim
   */
  @autobind
  goDetail(record) {
    window.open(`/why/claim_detail?id=${record.claimNo}`, "_blank");

  }
  @autobind
  goCreate() {
    window.open("/why/claim_create", "_blank");
  }
  @autobind
  clickImage(src_image) {
    console.log(src_image);
    this.setState({
      zoomImage: src_image,
      isOpen: true,
    });
  }

  @autobind
  insertEMS(record, type) {
    const { claimNo, shipping_date, tracking, rowNo } = record;

    if (type === 0) {
      this.setState({ visible: true, claimNo, type, rowNo });
    } else {
      this.setState({
        visible: true,
        claimNo,
        type,
        shipping_date: shipping_date,
        ems: tracking,
        rowNo,
      });
    }
  }

  @autobind
  handleCancel() {
    this.setState({
      visible: false,
      claim_doc: "",
      type: 0,
      shipping_date: moment(),
      ems: "",
    });
  }

  @autobind
  async handleOk() {
    this.setState({ isLoadingEMS: true }, async () => {
      try {
        const { ems, shipping_date, claimNo } = this.state;
        if (!claimNo || !ems) {
          // if (!claimNo) {
          this.setState({ isLoadingEMS: false });
          message.error("กรุณาใส่ข้อมูลให้ครบ");
          return;
        } else {
          if (!ems.trim()) {
            message.error("กรุณาไม่ใส่เว้นวรรค");
            this.setState({ isLoadingEMS: false });
          } else {
            console.log("ems=>", ems);
            const r = await POST(RIZZ_INSERT_EMS, {
              tracking: ems,
              shippingDate: shipping_date,
              claimNo,
            });
            if (r.success) {
              await this.onFetchClaim();
              this.setState({ isLoadingEMS: false, visible: false, ems: "" });
            }
          }
        }
      } catch (error) {
        this.setState({ visible: false, ems: "" });
      }
    });
  }


  @autobind
  onChange(e, ref) {
    this.setState({ [ref]: e });
  }

  @autobind
  onKeyPressSearch(event) {
    if (event.key === "Enter") {
      this.onSearch();
    }
  }

  @autobind
  onSearch() {
    const { searchText } = this.state;
    if (!searchText) {
      return this.setState({ data: defaultData });
    }
    console.log({ searchText });

    const d = defaultData.filter((el) => {
      return Object.values(el).some((val) => {
        return val
          ? val.toString().toLowerCase().includes(searchText.toLowerCase())
          : false;
      });
    });
    console.log({ d });

    this.setState({ data: d });
  }
  @autobind
  onChangeRangePicker(dates) {
    let start = moment(dates[0]).format("YYYY-MM-DD");
    let end = moment(dates[1]).format("YYYY-MM-DD");
    console.log(start);
    console.log(end);
    this.setState({
      selectedRowKeys: [],
      start_date: start,
      end_date: end,
      isLoadingData: true,
    });
    this.onFetchClaim(start, end);
  }

  @autobind
  async openExpand(expanded, recode) {
    // console.log(recode)
    const { claim_doc } = recode;

    let res = await POST(RIZZ_DETAILCLAIMBYID, { claim_doc });
    console.log(res);
    if (res.success) {
      let obj_image = this.state.obj_image;
      obj_image = { ...obj_image, [claim_doc]: res.result.detailclaim.url };
      this.setState({
        //  claim_image: res.result.url,
        obj_image,
      });
    }
  }
  @autobind
  async approveClaim() {
    //latest
    const { data, selectedRowKeys } = this.state;
    let result = [];
    let notcolect = [];
    console.log(selectedRowKeys);
    result = selectedRowKeys.map((sl) => {
      let r = data.filter((el) => el.claimNo === sl);
      if (r[0]) {
        return {
          claimNo: r[0].claimNo,
          barcode: r[0].barcode,
          claimStatus: r[0].claimStatus,
          // claimNo: r[0].claimNo
        };
      }
    });
    console.log("ap_result:", result);
    result.map((el) => {
      if (
        el.claimStatus !== 2 ||
        el.barcode === "" ||
        el.barcode === null ||
        el.barcode === undefined
      ) {
        console.log("ap_barcode:", el.barcode);
        console.log("ap_claimStatus:", el.claimStatus);
        notcolect.push(el.claimNo);
      }
    });
    console.log("ap_notcolect:", notcolect);
    console.log("ap_notcolect.length:", notcolect.length);
    if (notcolect.length > 0) {
      message.error(`สถานะงานเคลม :${notcolect} ไม่สามารถอนุมัติได้`);
    } else {
      this.setState({ isLoadingData: true });
      try {
        let res;
        await Promise.all(
          result.map(async (el) => {
            res = await POST(RIZZ_UPDATESTATUS, {
              claimListNo: el.claimNo,
              claimStatus: 3,
              rejectDetail: "",
              userLogin: this.state.userLoginId,
            });
          })
        );
        // console.log('ap_res:',res)
        if (res.success) {
          this.onFetchClaim();
          // this.setState({
          //     isLoadingData: false,
          // })
        }
      } catch (error) {
        message.error("ระบบขัดคล่องกรุณาลองใหม่ภายหลัง");
      }
    }
  }

  @autobind
  renderFormEditBarcode() {
    // console.log('FORM_EDIT_PRODUCT:',FORM_EDIT_PRODUCT)
    return FORM_EDIT_PRODUCT.map((el, i) => {
      const customProps = {
        items: el,
      };
      return <Row key={i}>{this._fn(el, customProps)}</Row>;
    });
  }

  @autobind
  _fn(el, customProps) {
    switch (el.type) {
      case "SEARCH":
        return (
          <SearchEditBarcode
            {...customProps}
            barcode={this.state.barcode}
            changeBarcode={this.handleChangeBarcode}
            searchBarcode={this.searchDetailByBarcode}
          />
        );
      case "TEXT_NAME":
        return (
          <NameEditBarcode {...customProps} prodName={this.state.productName} />
        );
      case "TEXT_TYPE":
        return (
          <TypeEditBarcode
            {...customProps}
            prodType={this.state.productTypeName}
          />
        );
      case "IMAGE":
        return (
          <ImageEditBarcode
            {...customProps}
            prodName={this.state.productName}
            prodImage={this.state.productImage}
            prodImageNoBarcode={this.state.productImageNoBarcode}
          />
        );
      case "DROPDOWN_BREAK":
        console.log("this.state.productTypeID", this.state.productTypeID);
        return this.state.productTypeID === 99 ||
          this.state.productTypeID === "99" ? (
          <BreakEditBarcodeOther
            {...customProps}
            prodBreak={this.state.claimDetail}
            onChangeBreakOther={this.handleChangeBreakOther}
            success={this.state.isSearchSuccess}
            alertBreak={this.state.disableEdit}
          />
        ) : (
          <BreakEditBarcode
            {...customProps}
            prodBreak={this.state.claimDetail}
            prodArrBreak={this.state.arr_breakdown}
            onChangeBreak={this.handleChangeBreak}
            success={this.state.isSearchSuccess}
            alertBreak={this.state.disableEdit}
          />
        );
    }
  }

  @autobind
  handleChangeBreak(e) {
    // console.log('e_breakID-',e.split('|')[0], 'e_breakName-',e.split('|')[1])
    const breakID = e.split("|")[0];
    const breakName = e.split("|")[1];
    this.setState({
      claimDetail: breakName,
      breakdownID: breakID,
      disableEdit: false,
    });
  }

  @autobind
  handleChangeBreakOther(e) {
    const val = e.trimLeft();
    if (val !== "" && val !== null && val !== undefined) {
      this.setState({ claimDetail: val, breakdownID: "", disableEdit: false });
    } else {
      this.setState({ claimDetail: val, breakdownID: "", disableEdit: true });
    }
  }


  createMessage = (record) => {
    this.setState({
      visibleSendMessage: true,
      dataSet: record,
      lineMessage: `เลขที่เคลม ${record.claimNo}: `,
    });
  };

  ////SEND MESSAGE/////

  async handleSendMessage() {
    try {
      this.setState({ loading: true });
      const { lineID } = this.state.dataSet;
      const message = this.state.lineMessage;

      if (!lineID) {
        Modal.error({ title: "ยังไม่ได้ OTP ผ่าน LINE" });
        return;
      }

      const r = await POST(SEND_MESSAGE_LINE, { lineID, message });
      if (r.success) {
        this.setState({ visibleSendMessage: false, });
        Modal.success({ title: r.message });
      }
    } catch (error) {
      Modal.error({ title: error.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  onModalNote(record) {
    this.setState({
      visibleModalNote: true,
      dataSet: record,
      note: record.note,
    });
  }


  async handleEditNote() {
    try {
      this.setState({ loading: true });
      const { claimNo } = this.state.dataSet;
      const { note } = this.state;

      const r = await POST(UPDATE_CLAIM_NOTE, { claimNo, note });
      if (r.success) {
        this.setState({ visibleModalNote: false });
        Modal.success({ title: r.message });
        this.onFetchClaim();
      }
    } catch (error) {
      Modal.error({ title: error.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      loading,
      selectedRowKeys,
      data,
      isLoadingData,
      shipping_date,
      ems,
      searchText,
      isOpen,
      visibleSendMessage,
      lineMessage,
      visibleModalNote,
      dataSet,
      note
    } = this.state;


    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div>
        {this.state.loading ? (
          <div>
            <Icon
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                fontSize: "6vw",
              }}
              type="loading"
            />
          </div>
        ) : (
          <div>
            {/*###################### MODAL EDIT-PRODUCT ######################*/}
            <Modal
              className="modalEditProduct"
              title={<p className="title-editProduct">แก้ไขสินค้า</p>}
              visible={this.state.isOpenEditProduct}
              // onOk={this.closeEditProduct}
              onCancel={this.closeEditProduct}
              // style={{ top: '20%' }}
              centered
              footer={[
                <Row key="save">
                  <Col key="save" span={24}>
                    <BT
                      key="submit"
                      className="btn-save-editProduct"
                      onClick={this.openConfirmEdit}
                      disabled={this.state.disableEdit}
                    >
                      บันทึกข้อมูล
                    </BT>
                  </Col>
                </Row>,
              ]}
            >
              {this.state.alertMessage.open ? (
                this.state.alertMessage.status == "loading" ? (
                  <Alert
                    style={{ marginTop: "10px", fontSize: ".8vw" }}
                    message={this.state.alertMessage.message}
                    type="info"
                  />
                ) : this.state.alertMessage.status == "success" ? (
                  <Alert
                    style={{ marginTop: "10px", fontSize: ".8vw" }}
                    message={this.state.alertMessage.message}
                    type="success"
                    showIcon
                  />
                ) : (
                  <Alert
                    style={{ marginTop: "10px", fontSize: ".8vw" }}
                    message={this.state.alertMessage.message}
                    type="error"
                    showIcon
                  />
                )
              ) : (
                <Alert
                  style={{ marginTop: "10px", fontSize: ".8vw" }}
                  message="กรุณาค้นหารหัส Barcode ก่อนบันทึกข้อมูล"
                  type="warning"
                  showIcon
                />
              )}

              {this.renderFormEditBarcode()}
            </Modal>
            <Modal
              title={
                <p style={{ margin: "0", fontSize: "1vw" }}>
                  <i
                    class="fas fa-exclamation-circle"
                    style={{ color: "red" }}
                  ></i>
                  <b style={{ marginLeft: "10px" }}>คำเตือน</b>
                </p>
              }
              visible={this.state.isConfirmEdit}
              onOk={this.confirmEditProduct}
              onCancel={this.closeConfirmEdit}
              footer={[
                <BT
                  key="submit"
                  classstyle={{
                    backgroundColor: "#4bad1a",
                    padding: "0 1vw",
                    fontSize: ".8vw",
                  }}
                  loading={loading}
                  onClick={this.confirmEditProduct}
                >
                  ใช่
                </BT>,
                <BT
                  key="back"
                  style={{ padding: "0 1vw", fontSize: ".8vw" }}
                  onClick={this.closeConfirmEdit}
                >
                  ไม่ใช่
                </BT>,
              ]}
              centered
            >
              <p style={{ margin: "0 10px", fontSize: ".8vw" }}>
                {this.state.confirmEditMessage}
              </p>
            </Modal>

            {/*###################### MODAL EMS ######################*/}
            <Modal
              title={<p style={{ textAlign: "center" }}>กรุณาใส่เลข EMS</p>}
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              width={"30vw"}
              confirmLoading={this.state.isLoadingEMS}
              cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
              okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
              okButtonProps={{
                style: {
                  backgroundColor: color.rizz_primary,
                  color: "#fff",
                  borderColor: color.rizz_primary,
                },
              }}
            >
              <ModalEMS
                obj={{ shipping_date, ems }}
                onChange={(e, ref) => this.onChange(e, ref)}
              />
            </Modal>
            {/*###################### MODAL SEND REMARK ######################*/}
            <Modal
              title={
                <p style={{ textAlign: "center", marginBottom: "0px" }}>
                  หมายเหตุ
                  <div>{`${dataSet.claimNo} ${dataSet.name} `}</div>
                </p>
              }
              visible={visibleModalNote}
              onOk={(e) => this.handleEditNote(e)}
              onCancel={() => {
                this.setState({ visibleModalNote: false });
              }}
              width={"35vw"}
              confirmLoading={loading}
              cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
              okText={<font style={{ fontSize: "1vw" }}>บันทึก</font>}
            >
              <Row style={{ marginBottom: "1em" }} gutter={16}>
                <Col span={24}>
                  <Input.TextArea
                    style={{
                      width: "100%",
                      alignItems: "right",
                      marginRight: "10px",
                    }}
                    value={note}
                    showCount={true}
                    maxLength={500}
                    onChange={(e) => this.onChange(e.target.value,'note')}
                    autosize={{ minRows: 6 }}
                  />
                </Col>
              </Row>
            </Modal>

            {/*###################### MODAL SEND MESSAGE ######################*/}
            <Modal
              title={
                <p style={{ textAlign: "center", marginBottom: "0px" }}>
                  <Icon type="message" /> ส่งข้อความ
                </p>
              }
              visible={visibleSendMessage}
              onOk={() => this.handleSendMessage()}
              onCancel={() =>
                this.setState({
                  visibleSendMessage: false,
                  dataSet: {},
                })
              }
              width={450}
              confirmLoading={this.state.isLoadingEMS}
              cancelText={<font style={{ fontSize: "1vw" }}>ยกเลิก</font>}
              okText={<font style={{ fontSize: "1vw" }}>ยืนยัน</font>}
              okButtonProps={{
                style: {
                  backgroundColor: color.focus_complete,
                  color: "#fff",
                  borderColor: color.focus_complete,
                },
              }}
            >


              <ModalSendMessage
                onChange={(e, ref) => this.onChange(e, ref)}
                data={{ lineMessage }}
              />
            </Modal>
            {isOpen && (
              <Lightbox
                mainSrc={this.state.zoomImage}
                onCloseRequest={() => this.setState({ isOpen: false })}
              />
            )}

            <div className={"column"}>
              {/*###################### HEAD ######################*/}
              <div className={"row-between"}>
                <BT
                  style={{ backgroundColor: "#32CD32" }}
                  onClick={() => this.exportClaim()}
                >
                  EXPORT
                </BT>
                <div style={{ display: "flex" }}>
                  <BT
                    type="primary"
                    onClick={this.start}
                    disabled={!hasSelected}
                    loading={loading}
                  >
                    บิลใบส่งสินค้า
                  </BT>
                  <span style={{ marginLeft: 8 }}>
                    {hasSelected
                      ? `เลือก ${selectedRowKeys.length} รายการ`
                      : ""}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Input.Search
                    style={{
                      width: "20vw",
                      borderRadius: "10vw",
                      paddingLeft: "1vw",
                      fontSize: "1.5vw",
                    }}
                    placeholder={"ค้นหาข้อมูล"}
                    value={searchText}
                    onKeyPress={(e) => this.onKeyPressSearch(e)}
                    onSearch={(value) => this.onSearch()}
                    onChange={(e) =>
                      this.onChange(e.target.value, "searchText")
                    }
                  />
                  <Button
                    title={"ยืนเรื่องเคลม"}
                    onPress={() => this.goCreate()}
                  />
                </div>
              </div>
              <div className={"row-between"}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <DatePicker.RangePicker
                    //value={moment(new Date())}
                    placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                    format="YYYY-MM-DD"
                    defaultValue={[
                      moment(this.state.start_date),
                      moment(this.state.end_date),
                    ]}
                    onChange={this.onChangeRangePicker}
                    size="default"
                  />
                </div>
              </div>
              <div className={"row-between"}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: 8 }}>
                    {hasSelected
                      ? `เลือก ${selectedRowKeys.length} รายการ`
                      : ""}
                  </span>

                  <BT
                    type="primary"
                    style={{
                      backgroundColor: color.rizz_primary,
                      borderColor: color.rizz_primary,
                    }}
                    onClick={this.approveClaim}
                    disabled={!hasSelected}
                    loading={loading}
                  >
                    อนุมัติเรื่องเคลม
                  </BT>
                </div>
              </div>

              {/*###################### TABLE ######################*/}
              <Table
                title={() => <h2>การเคลมสินค้า</h2>}
                rowSelection={rowSelection}
                columns={columnTableClaim(
                  (record) => this.goDetail(record),
                  (record, type) => this.insertEMS(record, type),
                  (record) => this.createMessage(record),
                  (record) => this.onModalNote(record),
                )}
                rowClassName={(record, i) =>
                  record.isDelay == 1 ? "late" : ""
                }
                dataSource={data}
                expandedRowRender={(record) => this.renderExpand(record)}
                bordered
                pagination={{ pageSize: 15 }}
                loading={isLoadingData}
                size="small"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
  renderExpand(record) {
    const { claimDetail, shiptoAddress, claimImage } = record;
    return (
      <div
        style={{ padding: "1vw", display: "flex", alignItems: "center" }}
        key={1}
      >
        {claimImage && (
          <img
            src={ip + "/image?path=" + claimImage}
            style={{ width: 100, height: 100 }}
            onClick={() => this.clickImage(ip + "/image?path=" + claimImage)}
            className={"contain"}
          />
        )}
        <div style={{ marginLeft: "1vw" }}>
          <div style={{ display: "flex", marginBottom: "-12px" }}>
            <p style={{ margin: 0, width: "100px" }}>อาการเสีย</p>
            <p>{claimDetail}</p>
          </div>

          <div style={{ display: "flex", marginBottom: "-12px" }}>
            <p style={{ margin: 0, width: "100px" }}>ที่อยู่จัดส่ง</p>
            <p>{` ${shiptoAddress}`}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default ClaimScene;

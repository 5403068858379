import cryptr from 'crypto-js'
import { KEY_BILL } from '../resource';
import moment from 'moment'
import { fbind } from 'q';
export const encryptParameter = (object) => {
    const encrypt = cryptr.AES.encrypt(JSON.stringify(object), KEY_BILL).toString();
    console.log(object, encrypt)
    return encrypt;
}

export const decryptParameter = (encrypt) => {
    return cryptr.AES.decrypt(encrypt, KEY_BILL).toString(cryptr.enc.Utf8);
}
export const append = (data) => {
    //file, name, telephone, province, district, subdistrict, zipcode, other, description, product_id, claim_id, status, line_id, claimdate }
    let fd = new FormData()
    fd.append('userId', data.line_id)
    fd.append('status', parseInt(data.status, 10))
    fd.append('name', data.name)
    fd.append('telephone', data.telephone)
    fd.append('province', data.province)
    fd.append('district', data.district)
    fd.append('amphure', data.subdistrict)
    fd.append('zipcode', data.zipcode)
    fd.append('other', data.other)
    fd.append('description', data.description)
    fd.append('note', data.note)
    fd.append('product_id', data.product_id)
    fd.append('claim_doc', data.claim_doc)
    fd.append('image', data.file)
    fd.append('create_at', data.claimdate)
    fd.append('addressId', data.addressId)
    fd.append('type_sending', data.type_sending)



    return fd
}
export const append_edit = (data) => {
    //file, name, telephone, province, district, subdistrict, zipcode, other, description, product_id, claim_id, status, line_id, claimdate }
    let fd = new FormData()
    fd.append('userId', data.line_id)
    fd.append('status', parseInt(data.status, 10))
    fd.append('name', data.name)
    fd.append('telephone', data.telephone)
    fd.append('province', data.province)
    fd.append('district', data.district)
    fd.append('amphure', data.subdistrict)
    fd.append('zipcode', data.zipcode)
    fd.append('other', data.other)
    fd.append('description', data.description)
    fd.append('note', data.note)
    fd.append('product_id', data.product_id)
    fd.append('claim_doc', data.claim_doc)
    fd.append('image', data.file)
    fd.append('create_at', data.claimdate)
    fd.append('addressId', data.addressId)
    fd.append('type_sending', data.type_sending)
    fd.append('product_name', data.product_name)


    return fd
}
export const append_focus_createclaim = (data) => {
    //file, name, telephone, province, district, subdistrict, zipcode, other, description, product_id, claim_id, status, line_id, claimdate }
    let fd = new FormData()
    console.log(data.user_id)


    fd.append('province', data.province)
    fd.append('district', data.district)
    fd.append('amphure', data.subdistrict)
    fd.append('zipcode', data.zipcode)
    fd.append('other', data.other)
    fd.append('type', data.type)
    fd.append('no_warranty', data.no_warranty)
    fd.append('image', data.file)
    fd.append('create_at', data.claimdate)
    fd.append('channel', data.channal)
    fd.append('tmp_cid', '')
    fd.append('detail', data.description)
    fd.append('user_id', data.user_id)




    return fd
}

export const append_focus_claim = (data) => {
    let fd = new FormData()
    console.log('append', data)
    fd.append('province', data.province)
    fd.append('district', data.district)
    fd.append('amphure', data.subdistrict)
    fd.append('zipcode', data.zipcode)
    fd.append('other', data.other)
    fd.append('description', data.description)
    fd.append('note', data.note)
    fd.append('no_claim', data.claim_doc)
    fd.append('image', data.file)
    fd.append('item_code', data.item_code)
    fd.append('no_warranty', data.no_warranty)
    fd.append('product_id', data.id_product)
    fd.append('nameproduct', data.product_name)
    fd.append('brandmobile', data.brand_name)
    fd.append('modelmobile', data.model_name)
    return fd
}
export const append_focus_pay = (data) => {
    let fd = new FormData()
    console.log('date', data.date_pay)
    let time_pay = moment(data.time_pay).format('HH.mm') === 'Invalid date' ? data.time_pay : moment(data.time_pay).format('HH.mm')
    let date_pay = data.date_pay === null ? moment(new Date()).format('YYYY-MM-DD') : moment(data.date_pay).format('YYYY-MM-DD')//moment(data.date_pay).format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD')
    console.log('user_id', data.user_id)
    console.log('date2', date_pay)

    fd.append('no_warranty', data.no_warranty)
    fd.append('image', data.file_pay)
    fd.append('bank_pay', data.bank_pay || '')
    fd.append('time_pay', time_pay || moment(new Date()).format('HH.mm'))
    fd.append('date_pay', date_pay || moment(new Date()).format('YYYY-MM-DD'))
    fd.append('money_pay', data.money_pay || '')
    fd.append('user_id', data.user_id || '')
    fd.append('tel', data.telephone)
    fd.append('no_claim', data.claim_doc)
    return fd
}

export const appen_register = (data) => {

    let fd = new FormData()

    fd.append('image', data.fileProduct)
    fd.append('line_id', data.line_id)
    fd.append('name', data.name)
    fd.append('telephone', data.telephone)
    fd.append('register_date', data.register_date)
    fd.append('product_name', data.product_name)
    fd.append('warranty_day', data.warranty_month)
    if (data.fileSlip) {
        fd.append('image', data.fileSlip)
    }
    return fd

}
export const dateDiff = (endDate) => {
    const now = moment(new Date())
    const end = moment(endDate)
    const days = now.diff(end, 'days')
    return days

}
export const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
export const phonNumberValidate = (phone) => {
    let isphone = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(phone);
    // console.log(isphone)
    return isphone
    //console.log(phone.match(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/).length === 9)
}
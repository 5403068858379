import React from "react";
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Modal,
  Select,
  Switch,
  Button,
  Upload,
} from "antd";
import { image } from "../resource/images/";

export const UploadImage = ({ handleUpload, img }) => {
  console.log({ img });
  return (
    <React.Fragment>
      <Row>
        <Col span={12}>
          {" "}
          <div class="upload-btn-wrapper">
            <Button style={{ backgroundColor: "#1890ff", color: "#fff" }}>
              {"อัพโหลดรูป"}
            </Button>
            <input
              type="file"
              onChange={handleUpload}
              style={{
                fontSize: "100px",
                position: "absolute",
                left: 0,
                top: 0,
                opacity: 0,
              }}
            />
          </div>
        </Col>
        <Col span={12}>
          {img && (
            <div style={{ border: "1px solid #dadada", width: "152px" }}>
              <img src={img} alt="preview" width="150" hieght="150" />
            </div>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

import React, { Component } from "react";
import { observer } from "mobx-react";
import QRCode from "qrcode.react";
import autobind from "autobind-decorator";
import { decryptParameter } from "../../../functions/functions";
import { POST, RIZZ_GETREPORT } from "../../../service";
import { Spin, Table } from "antd";
import { image, columnTableReport } from "../../../resource";
import { Button } from "../../../components/Button";

@observer
class BillShippingScene extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // loading: true,
      print: false,
      data: [],
    };
  }

  async componentDidMount() {
    await this.setState({ detail: this.props.location.state.item }, () =>
      console.log("detail_bill", this.state.detail)
    );
    // this.getStateForEdit()
    window.onbeforeprint = () => {
      this.setState({ print: true });
    };
    window.onafterprint = () => {
      this.setState({ print: false });
    };
    // if (!!this.props.location && !!this.props.location.state ) {
    //     this.props.history.push('/rizz/claim')
    // }
  }

  getStateForEdit() {
    const { detail } = this.state;
    this.setState({ item: detail }, () =>
      console.log("item:", this.state.item)
    );
  }

  @autobind
  async onFetchReport() {
    try {
      const { claim_id } = JSON.parse(
        decryptParameter(this.props.location.state.id)
      );
      console.log("id:", claim_id);
      const res = await POST(RIZZ_GETREPORT, {
        claim_id: JSON.stringify(claim_id),
      });
      if (res.success) {
        console.log(res.result);
        this.setState({ loading: false, data: res.result });
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    let { loading, data, print } = this.state;

    console.log("itemz:", this.state.detail);
    if (loading) {
      return (
        <div className="example">
          <Spin />
        </div>
      );
    }
    return !!this.state.detail ? (
      <div className={"body-print"}>
        {this.state.detail.map((el, i) => {
          // const { claim_id, customer, address, product } = el;
          const {
            claimRemark,
            customerName,
            productName,
            telephone,
            shiptoAddress,
            claimNo,
          } = el;
          // const item = [{
          //     key: i,
          //     no: 1,
          //     product_name: product.product_name,
          //     product_image: product.url,
          //     count: 1
          // }]

          return (
            <div className={"container-bill"} key={i}>
              {/* <div className={'border-print'}> */}
              {/* <div className={'section1'}> */}
              <div
                className={"row"}
                style={{ fontSize: "0.4cm", marginTop: 50 }}
              >
                <img src={image.why_logo} className={"image-logo"} alt={""} />
                <div className={"column"} style={{ marginLeft: "0.5cm" }}>
                  <font style={{ fontSize: "0.4cm" }}>
                    บริษัท ดีพลัสอินเตอร์เทรด จำกัด
                  </font>
                  <font style={{ fontSize: "0.4cm" }}>
                    123/20-22 ถ.นนทรี แขวงช่องนนทรี
                  </font>
                  <font style={{ fontSize: "0.4cm" }}>
                    เขตยานนาวา กทม 10120 โทร 02 294484 8
                  </font>
                </div>
              </div>
              <div style={{ marginTop: 30 }} className={"content-section1"}>
                <font>ชื่อที่อยู่/ผู้รับ</font>
                <font>จัดส่งคุณ: {customerName}</font>
                <font>ที่อยู่: {shiptoAddress}</font>
                <font>เบอร์โทรศัพท์: {telephone}</font>
                <font>สินค้า:{productName} </font>
                {el.note && <font>หมายเหตุ:{claimRemark} </font>}
                <div style={{ alignSelf: "flex-end" }}>
                  <QRCode value={claimNo} size={120} />
                </div>
                {/* <div style={{ alignSelf: 'flex-end' }}>
                                    <QRCode
                                        value={`http://emsbot.com/#/?s=${claim_id}`}
                                        size={120}
                                    />
                                </div> */}
                {/* </div> */}
                {/* </div> */}

                {/* <div className={'section1'}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <font style={{ alignSelf: 'flex-end', fontSize: ' 0.6cm', marginTop: '0.4cm' }}>ใบจัดสินค้า</font>
                                </div>
                                <div className={'content-section2'}>
                                    <font>ชื่อและที่อยู่ที่รับ</font>
                                    <font>จัดส่งคุณ: {customer.name} - EMS</font>
                                    <font>ที่อยู่: {`${address.other} ต.${address.district} อ.${address.amphure}`} {'\n'}
                                        {`จ.${address.province} ${address.zipcode}`}
                                    </font>
                                    <font>เบอร์โทรศัพท์: {customer.telephone}</font>

                                    <Table
                                        columns={columnTableReport}
                                        className={'table-report'}
                                        // rowSelection={{ selections: false }}
                                        dataSource={item}
                                        bordered
                                        pagination={false}
                                        style={{ marginTop: '1cm' }}
                                        size="small"
                                    />
                                </div>
                            </div> */}
              </div>
            </div>
          );
        })}
        {!print && (
          <div
            style={{
              textAlign: "center",
              marginTop: "1cm",
              marginBottom: "1cm",
            }}
          >
            <Button
              title={"ยกเลิก"}
              type={"secondary"}
              onPress={() => this.props.history.goBack()}
            />
            <Button
              title={"PRINT"}
              onPress={() =>
                this.setState({ print: true }, () => window.print())
              }
            />
          </div>
        )}
      </div>
    ) : (
      <div>loading...</div>
    );
  }
}

export default BillShippingScene;

import React, { Component } from 'react'
import { observer } from 'mobx-react';
import { Table, Input,Icon } from 'antd';
import Lightbox from 'react-image-lightbox';
import { columnTableHistoryClaim } from '../../../resource';
import autobind from 'autobind-decorator';
import { ip ,POST , RIZZ_GETHISTORY } from '../../../service';
// import dayjs from 'dayjs';

let defaultData = []

@observer
class HistoryScene extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            isLoadingData: false,
            searchText: '',
            isOpen: false,
            zoomImage: '',
        }
    }

    componentDidMount() {
            this.onFetchHistory()
    }

    @autobind
    async onFetchHistory() {
        try {
            const res = await POST(RIZZ_GETHISTORY, '');
            let data = [];
            if (res.success) {
                console.log('res_histrory',res.result)
                data = res.result.map((el, i) => {
                    // const { product, customer, data } = el;
                    return {
                        ...el,
                        key: el.rowNo,
                        no: el.rowNo,
                        line_name: el.lineName,
                        name: el.customerName,
                        telephone: el.customerTelephone,
                        product_register: el.warrantyNo,
                        product_name: el.productName,
                        count: el.qty,
                        last_date: el.claimDate,

                        // key: i + 1,
                        // ...el,
                        // no: i + 1,
                        // name: customer.name,
                        // telephone: customer.telephone,
                        // product_name: product.product_name,
                        // product_register: product.product_id,
                        // last_date: dayjs(el.create_at).add(543, 'y').format('DD/MM/YYYY'),
                        // data
                    }
                })
            }

            defaultData = data;

            this.setState({ isLoadingData: false, data: defaultData, loading: false })
        } catch (error) {
            console.log(error)
            this.setState({ isLoadingData: false })
        }
    }

    @autobind
    onChange(e, ref) {
        this.setState({ [ref]: e })
    }

    @autobind
    onKeyPressSearch(event) {
        if (event.key === 'Enter') {
            this.onSearch()
        }
    }

    @autobind
    onSearch() {
        const { searchText } = this.state;
        if (!searchText) {
            return this.setState({ data: defaultData })
        }

        const d = defaultData.filter(el => {
            return Object.values(el).some(val => {
                return val ? val.toString().toLowerCase().includes(searchText.toLowerCase()) : false
            })
        })

        this.setState({ data: d })
    }


    render() {
        const { data, isLoadingData, searchText } = this.state;

        return (
            <div>
                {this.state.loading?
                <div>
                    <Icon style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', fontSize: '6vw' }} type='loading'/>
                </div>
                :
                <div className={'column'}>
                    {/*###################### HEAD ######################*/}
                    <div className={'row-between'}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
                            <Input.Search
                                style={{ width: '20vw', borderRadius: '10vw', paddingLeft: '1vw',fontSize: '32px' }}
                                placeholder={'ค้นหาข้อมูล'}
                                value={searchText}
                                onKeyPress={e => this.onKeyPressSearch(e)}
                                onSearch={() => this.onSearch()}
                                onChange={e => this.onChange(e.target.value, 'searchText')}
                            />
                        </div>
                    </div>

                    {/*###################### TABLE ######################*/}
                    <Table
                        title={() => <h2>ประวัติการเคลม</h2>}
                        // rowSelection={rowSelection}
                        columns={columnTableHistoryClaim}
                        rowClassName={(record, i) => i % 2 === 1 ? 'even' : ''}
                        dataSource={data}
                        expandedRowRender={record => this.renderExpand(record)}
                        bordered
                        pagination={{ pageSize: 15 }}
                        loading={isLoadingData}
                        size="small"
                    />
                </div>
                }
            </div>
        )
    }

    @autobind
    clickImage(src_image) {
        console.log(src_image)
        this.setState({
            zoomImage: src_image,
            isOpen: true
        })
    }


    renderExpand(record) {
        return record.claimList.map((val,i)=> {
            console.log('val',val)
            return (
                <div key={i} style={{ padding: '1vw', display: 'flex', alignItems: 'center' }} >
                    <img src={ ip + '/image?path=' + val.claimImage } style={{ width: 200, height: 200 }} 
                        onClick={() => this.clickImage(ip + '/image?path=' + val.claimImage)} className={'contain'} />
                    <div style={{ marginLeft: '1vw' }}>
                        <div style={{ display: 'flex' }}>
                            <b style={{ margin: '0 10px 0 0' }} >เลขที่การเคลม:</b>
                            <p>{val.claimNo}</p>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <b style={{ margin: '0 10px 0 0' }} >เลขที่ลงทะเบียน:</b>
                            <p>{val.warrantyNo}</p>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <b style={{ margin: '0 10px 0 0' }} >ชื่อสินค้า:</b>
                            <p>{val.productName}</p> 
                        </div>
                        <div style={{ display: 'flex' }}>
                            <b style={{ margin: '0 10px 0 0' }} >วันที่แจ้งเคลม:</b>
                            <p>{val.claimDate}</p>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <b style={{ margin: '0 10px 0 0' }} >อาการเสีย:</b>
                            <p>{val.claimDetail}</p>
                        </div>
                    </div>
                    {this.state.isOpen && (
                    <Lightbox
                        mainSrc={this.state.zoomImage}

                        onCloseRequest={() => this.setState({ isOpen: false })}

                    />
                    )}
                </div>
            )
        })
    }
}

export default HistoryScene
import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  Row,
  Col,
  Timeline,
  Icon,
  Button as BT,
  Modal,
  Spin,
  Input,
  message,
} from "antd";
import autobind from "autobind-decorator";
import moment from "moment";
import { Button } from "../../../components/Button";
import {
  ip,
  POST,
  RIZZ_DETAILCLAIMBYID,
  RIZZ_UPDATESTATUS,
  RIZZ_GET_DETIAL_BY_CLAIMNO,
  GET,
} from "../../../service";
import { columnTableClaim, color, image } from "../../../resource";
import { ModalReject } from "../../../components/Modal";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import socketIOClient from "socket.io-client";

const fn = require("../../../functions/functions");

const { confirm } = Modal;
const styles = {
  font: {
    fontSize: "0.8vw",
    marginTop: 3,
  },
  border: {
    border: "0.5px",
    borderStyle: "solid",
    borderColor: "#CCCCCC",
  },
  border_part1: {
    border: "0.5px",
    borderStyle: "solid",
    borderColor: "#CCCCCC",
    marginTop: 15,
  },
};

@observer
class DetailClaimScene extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      claimNo: query.get("id"),
      loading: true,
      user: [],
      address: [],
      product: [],
      visible: false,
      reject_detail: "",
      vb_reject: false,
      isLoadingReject: false,
      isOpen: false,
      detail: "",
      userLoginID: "",
    };
  }

  async componentDidMount() {
    this.setState({ userLoginID: localStorage.getItem("userLoginId") });
    // console.log('test1', this.state.claimNo)
    await this.getDetailByClaimNo(this.state.claimNo);
  }

  @autobind
  async getDetailByClaimNo(claimNo) {
    try {
      const r = await GET(RIZZ_GET_DETIAL_BY_CLAIMNO + claimNo);
      if (r.success) {
        this.setState({ detail: r.result, loading: false });
      }
    } catch (err) {}
  }

  @autobind
  showModal() {
    this.setState({
      visible: true,
    });
  }
  autobind;
  rejectModal() {
    this.setState({
      vb_reject: true,
    });
  }
  @autobind
  onChange(e, ref) {
    console.log("reject_detail", e);
    this.setState({
      [ref]: e,
    });
  }
  @autobind
  async handleOk() {
    const { userLoginID } = this.state;
    // this.setState({ loading: true, status: 1 })
    // console.log('status', status)
    try {
      let res = await POST(RIZZ_UPDATESTATUS, {
        claimListNo: this.state.claimNo,
        claimStatus: 3,
        rejectDetail: "",
        userLogin: userLoginID,
      });
      if (res.success) {
        // this.onFetchClaimById()
        this.setState({
          visible: false,
        });
        message.success("ยื่นดำเนินการสำเร็จ");
        this.getDetailByClaimNo(this.state.claimNo);

        this.sendToSocket();
      } else {
        message.error("เกิดข้อผิดพลาด");
      }
    } catch (error) {
      message.error(`เกิดข้อผิดพลาด(${error.message})`);
    }
  }

  @autobind
  sendToSocket() {
    const socket = socketIOClient("https://dplus-service.com:1141");
    console.log("sendToSocket");
    socket.emit("afterApprove", "success");
  }

  @autobind
  handleCancel() {
    this.setState({
      visible: false,
    });
  }
  @autobind
  gotoEdit() {
    this.props.history.push(`/why/claim_edit?id=${this.state.claimNo}`);
  }
  @autobind
  goBack() {
    this.props.history.goBack();
  }
  @autobind
  checkdeta() {
    const { reject_detail } = this.state;
    console.log(reject_detail);
  }
  @autobind
  async handlereject() {
    const { userLoginID, reject_detail } = this.state;
    if (reject_detail === "") {
      message.error("กรุณาใส่เหตุผลที่ไม่อนุมัติ");
    } else {
      this.setState({
        isLoadingReject: true,
      });
      try {
        let res = await POST(RIZZ_UPDATESTATUS, {
          claimListNo: this.state.claimNo,
          claimStatus: 4,
          rejectDetail: reject_detail,
          userLogin: userLoginID,
        });
        if (res.success) {
          this.setState({
            vb_reject: false,
            isLoadingReject: false,
          });
          this.getDetailByClaimNo(this.state.claimNo);
          this.sendToSocket();
          message.success("ยกเลิกการเคลมสำเร็จ");
        } else {
          message.error("เกิดข้อผิดพลาด");
        }
      } catch (error) {
        message.error(`เกิดข้อผิดพลาด(${error.message})`);
      }
    }
  }
  @autobind
  cancelreject() {
    this.setState({
      vb_reject: false,
    });
  }
  @autobind
  clickImage(src_image) {
    console.log(src_image);
    this.setState({
      zoomImage: src_image,
      isOpen: true,
    });
  }

  render() {
    const {
      isOpen,
      reject_detail,
      status,
      visible,
      loading,
      detail,
    } = this.state;
  
    const handler_claim =
      detail.claimStatus === 1 ||
      detail.claimStatus === 3 ||
      detail.claimStatus === 4 ||
      detail.barcode === "" ||
      detail.barcode === null ||
      detail.barcode === undefined
        ? false
        : true;
    const handle_cancel =
      detail.claimStatus === 4 || detail.claimStatus === 1 ? false : true;

    return (
      <div>
        {this.state.loading ? (
          <div>
            <Icon
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                fontSize: "6vw",
              }}
              type="loading"
            />
          </div>
        ) : (
          <div>
            <Modal
              title={
                <p style={{ margin: 0, fontSize: "1.2vw" }}>
                  เหตุผลที่ไม่อนุมัติ
                </p>
              }
              visible={this.state.vb_reject}
              onOk={this.handlereject}
              onCancel={this.cancelreject}
              width={"30vw"}
              confirmLoading={this.state.isLoadingReject}
              footer={[
                <BT
                  key="submit"
                  onClick={() => this.handlereject()}
                  style={{
                    backgroundColor: color.rizz_primary,
                    color: "#FFF",
                    borderColor: color.rizz_primary,
                  }}
                  loading={loading}
                >
                  ยืนยัน
                </BT>,
                <BT key="back" onClick={this.cancelreject}>
                  ยกเลิก
                </BT>,
              ]}
              centered
            >
              <ModalReject
                obj={{ reject_detail }}
                onChange={(e, ref) => this.onChange(e, ref)}
              />
            </Modal>
            <Modal
              title={
                <p style={{ margin: 0, fontSize: "1.2vw" }}>
                  ยืนยันอนุมัติการเคลม
                </p>
              }
              visible={visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              width={"30vw"}
              footer={[
                <BT
                  key="submit"
                  onClick={() => this.handleOk()}
                  style={{
                    backgroundColor: "#656565",
                    color: "#FFF",
                    borderColor: "#656565",
                  }}
                  loading={loading}
                >
                  ยืนยัน
                </BT>,
                <BT key="back" onClick={this.handleCancel}>
                  ยกเลิก
                </BT>,
              ]}
              centered
            >
              <p style={{ margin: 0, fontSize: "1vw" }}>
                กรุณากดยืนยันเพื่อรับงานเคลม หมายเลข:{" "}
                <span style={{ color: "blue" }}>{this.state.claimNo}</span>
              </p>
            </Modal>
            {isOpen && (
              <Lightbox
                mainSrc={this.state.zoomImage}
                onCloseRequest={() => this.setState({ isOpen: false })}
              />
            )}
            {/* <Edit address={address.zipcode}></Edit> */}
            {/* #################################   สถานะ  #################################*/}
            <Row>
              <Col span={6} push={18}>
                <div
                  style={{
                    border: "0.5px",
                    borderStyle: "solid",
                    borderColor: "#FFFFFF",
                    marginLeft: "0.8vw",
                  }}
                >
                  <div
                    className={"column"}
                    style={{
                      margin: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h3 style={{ fontSize: "1vw" }}>สถานะ</h3>
                  </div>
                  <div
                    className={"column"}
                    style={{
                      margin: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Timeline>
                      <Timeline.Item
                        dot={
                          <Icon
                            type="check-circle"
                            style={{
                              fontSize:
                                detail.claimStatus === 2 ? "1.5vw" : "1.1vw",
                            }}
                          />
                        }
                        color={detail.claimStatus === 2 ? "red" : "black"}
                        style={{ marginTop: 20 }}
                      >
                        <font
                          className={"text-status"}
                          style={{ fontSize: ".8vw" }}
                        >
                          รอตรวจสอบ
                        </font>
                      </Timeline.Item>
                      <Timeline.Item
                        dot={
                          <Icon
                            type="check-circle"
                            style={{
                              fontSize:
                                detail.claimStatus === 3 ? "1.5vw" : "1.1vw",
                            }}
                          />
                        }
                        color={detail.claimStatus === 3 ? "red" : "black"}
                        style={{ marginTop: 15 }}
                      >
                        <font
                          className={"text-status"}
                          style={{ fontSize: ".8vw" }}
                        >
                          ดำเนินการ
                        </font>
                      </Timeline.Item>
                      <Timeline.Item
                        dot={
                          <Icon
                            type="check-circle"
                            style={{
                              fontSize:
                                detail.claimStatus === 1 ? "1.5vw" : "1.1vw",
                            }}
                          />
                        }
                        color={detail.claimStatus === 1 ? "green" : "black"}
                        style={{ marginTop: 15 }}
                      >
                        <font
                          className={"text-status"}
                          style={{ fontSize: ".8vw" }}
                        >
                          จัดส่งเรียบร้อย
                        </font>
                      </Timeline.Item>
                      <Timeline.Item
                        dot={
                          <Icon
                            type="check-circle"
                            style={{
                              fontSize:
                                detail.claimStatus === 4 ? "1.5vw" : "1.1vw",
                            }}
                          />
                        }
                        color={detail.claimStatus === 4 ? "red" : "black"}
                        style={{ marginTop: 15 }}
                      >
                        <font
                          className={"text-status"}
                          style={{ fontSize: ".8vw" }}
                        >
                          ยกเลิกเคลม
                        </font>
                      </Timeline.Item>
                      {/* <Timeline.Item dot={<Icon type="check-circle" style={{ fontSize: status === 2 ? '26px' : '20px' }} />} color={status === 2 ? 'green' : 'black'} style={{ marginTop: 15 }}> จัดส่งเรียบร้อย</Timeline.Item> */}
                      {/* <Timeline.Item dot={<Icon type="check-circle" style={{ fontSize: status === 99 ? '26px' : '20px' }} />} color={status === 99 ? 'red' : 'black'} style={{ marginTop: 15 }}> ไม่อนุมัติ</Timeline.Item> */}
                    </Timeline>
                  </div>
                </div>
              </Col>
              {/* #################################   ข้อมูลส่วนตัว  #################################*/}
              <Col span={18} pull={6}>
                <div
                  style={{
                    border: "0.5px",
                    borderStyle: "solid",
                    borderColor: "#CCCCCC",
                    margin: 15,
                  }}
                >
                  <div className={"column"} style={{ margin: 10 }}>
                    <h3 style={{ fontSize: "1vw" }}>ข้อมูลส่วนตัว</h3>
                  </div>

                  <div
                    className={"column"}
                    style={{ margin: 15, marginTop: -15, marginLeft: 25 }}
                  >
                    <div className={"row-between"}>
                      <font style={styles.font}>Line Name</font>
                      <font style={styles.font}>{detail.lineName}</font>
                    </div>
                    <div className={"row-between"} style={{ marginTop: 10 }}>
                      <font style={styles.font}>ชื่อ-นามสกุล</font>
                      <font style={styles.font}>{detail.customerName}</font>
                    </div>
                    <div className={"row-between"} style={{ marginTop: 10 }}>
                      <font style={styles.font}>หมายเลขโทรศัพท์</font>
                      <font style={styles.font}>{detail.telephone}</font>
                    </div>
                    <div className={"row-between"} style={{ marginTop: 10 }}>
                      <div>
                        <font style={styles.font}>ที่อยู่</font>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <font style={{ ...styles.font, marginLeft: 3 }}>
                            {detail.shiptoAddress}
                          </font>
                        </div>
                      </div>
                    </div>
                    <div className={"row-between"} style={{ marginTop: 10 }}>
                      <font style={styles.font}>อีเมล(เพิ่มเติม)</font>
                      <font style={styles.font}>
                        {!!detail.email ? detail.email : ""}
                      </font>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {/* #################################   button  #################################*/}
            <Row>
              <Col span={6} push={18}></Col>
              {/* #################################   ข้อมูลการแจ้งเคลม  #################################*/}
              <Col span={9} pull={6}>
                <div
                  style={{
                    border: "0.5px",
                    borderStyle: "solid",
                    borderColor: "#CCCCCC",
                    margin: 15,
                    marginTop: -3,
                  }}
                >
                  <div className={"column"} style={{ margin: 10 }}>
                    <h3 style={{ fontSize: "1vw" }}>ข้อมูลการลงทะเบียน</h3>
                  </div>
                  <div
                    className={"column"}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {detail.productImage !== "" &&
                    detail.productImage !== null &&
                    detail.productImage !== undefined ? (
                      <img
                        src={
                          detail.productImage
                            ? ip + "/" + detail.productImage
                            : image.why_logo
                        }
                        onClick={() =>
                          this.clickImage(
                            detail.productImage
                              ? ip + "/" + detail.productImage
                              : image.why_logo
                          )
                        }
                        alt="new"
                        style={{
                          width: "6cm",
                          height: "7cm",
                          marginBottom: 50,
                        }}
                      />
                    ) : (
                      <img
                        src={
                          detail.warrantyImage
                            ? ip + "/image?path=" + detail.warrantyImage
                            : image.why_logo
                        }
                        onClick={() =>
                          this.clickImage(
                            detail.warrantyImage
                              ? ip + "/image?path=" + detail.warrantyImage
                              : image.why_logo
                          )
                        }
                        alt="new"
                        style={{
                          width: "6cm",
                          height: "7cm",
                          marginBottom: 50,
                        }}
                      />
                    )}
                  </div>
                </div>
              </Col>
              <Col span={9} pull={6}>
                <div
                  style={{
                    border: "0.5px",
                    borderStyle: "solid",
                    borderColor: "#CCCCCC",
                    margin: 15,
                    marginTop: -3,
                  }}
                >
                  <div className={"column"} style={{ margin: 10 }}>
                    <h3 style={{ fontSize: "1vw" }}>ข้อมูลการแจ้งเคลม</h3>
                  </div>
                  <div
                    className={"column"}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <img
                      src={
                        detail.claimImage
                          ? ip + "/image?path=" + detail.claimImage
                          : image.why_logo
                      }
                      onClick={() =>
                        this.clickImage(
                          detail.claimImage
                            ? ip + "/image?path=" + detail.claimImage
                            : image.why_logo
                        )
                      }
                      alt="new"
                      style={{ width: "6cm", height: "7cm", marginBottom: 50 }}
                    />
                  </div>
                </div>
              </Col>
              {/* #################################   รายละเอียดการเคลม  #################################*/}
            </Row>
            <Row>
              <Col span={6} push={18}>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    margin: 15,
                    marginTop: -3,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      margin: 10,
                      justifyContent: "center",
                    }}
                  >
                    {/* <Button title={'แก้ไข'} onPress={() => this.gotoEdit(claim_doc)} /> */}
                    <BT
                      type="primary"
                      style={{ width: "4vw", height: "20vw" }}
                      onClick={() => this.gotoEdit()}
                      disabled={!handle_cancel}
                    >
                      แก้ไข
                    </BT>
                    <BT
                      type="primary"
                      style={{ width: "8vw", height: "20vw" }}
                      onClick={() => this.showModal()}
                      disabled={!handler_claim}
                    >
                      อนุมัติการเคลม
                    </BT>
                  </div>
                </div>
                <div
                  className={"column"}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: -10,
                  }}
                >
                  {/* <Button title={'ไม่อนุมัตื'} style={{ width: '12vw', height: '20vw' }} onPress={() => this.rejectModal()} /> */}
                  <BT
                    type="primary"
                    style={{
                      width: "12vw",
                      height: "20vw",
                      backgroundColor: "red",
                    }}
                    onClick={() => this.rejectModal()}
                    disabled={!handle_cancel}
                  >
                    ยกเลิกการเคลม
                  </BT>
                </div>
              </Col>
              <Col span={18} pull={6}>
                <div
                  style={{
                    border: "0.5px",
                    borderStyle: "solid",
                    borderColor: "#CCCCCC",
                    margin: 15,
                    marginTop: -3,
                  }}
                >
                  <div style={{ margin: 15, marginLeft: 15 }}>
                    <div className={"row-between"} style={{ marginTop: 20 }}>
                      <font style={styles.font}>เลขที่การเคลม</font>
                      <font style={styles.font}>{this.state.claimNo}</font>
                    </div>
                    <div className={"row-between"} style={{ marginTop: 20 }}>
                      <font style={styles.font}>รหัสลงทะเบียนสินค้า</font>
                      <font style={styles.font}>{detail.warrantyNo}</font>
                    </div>
                    <div className={"row-between"} style={{ marginTop: 20 }}>
                      <font style={styles.font}>ชื่อสินค้าผลิตภัณฑ์</font>
                      <font style={styles.font}>{detail.productName}</font>
                    </div>
                    <div className={"row-between"} style={{ marginTop: 20 }}>
                      <font style={styles.font}>วันที่แจ้งเคลม</font>
                      <font style={styles.font}>
                        {moment(detail.claimDate).format("DD-MM-YYYY HH:mm")}
                      </font>
                    </div>
                    <div className={"row-between"} style={{ marginTop: 20 }}>
                      <font style={styles.font}>อาการเสีย</font>
                      <font style={styles.font}>{detail.claimDetail}</font>
                    </div>
                    <div className={"row-between"} style={{ marginTop: 20 }}>
                      <font style={styles.font}>หมายเหตุ</font>
                      <font>
                        {!!detail.claimRemark ? detail.claimRemark : ""}
                      </font>
                    </div>
                    <div
                      className={"column"}
                      style={{
                        marginTop: 10,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <font style={{ fontSize: "0.ุ7vw", marginTop: 5 }}>
                        แจ้งเคลมมาแล้ว {fn.dateDiff(detail.claimDate)} วัน
                      </font>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default DetailClaimScene;

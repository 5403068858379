export const color = {
  bg_login: "#DEDEDE",
  rizz_main: "#FFD100", //D41B24
  rizz_secondary: "#24231D",
  rizz_primary: "#FFD100",
  rizz_ems: "#FFD100",
  rizz_process: "#FF9300",
  rizz_complete: "#60D936",
  rizz_cancel: "#adadad",
  rizz_register: "#000",

  dplus_primary: "#0783c1",
  dplus_main: "#3782BC",
  dplus_secondary: "#24231D",

  focus_waitpay: "#FF9300",
  focus_waittocheck: "#EE220C",
  focus_successpay: "#FFFB01",
  focus_complete: "#60D936",
};

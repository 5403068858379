import React from "react";
import ClaimScene from "../scene/Why/Claim/ClaimScene";
import RegisterWarranty from "../scene/Why/Warranty/RegisterWarranty";
import { BtnConfirm } from "../components/Button";
import { Tag, Icon } from "antd";
import {
  EditOutlined 
} from "@ant-design/icons";
import {
  ColumnStatus,
  ColumnStatus_FOCUS,
  ColumnStatus_ImportAx_Focus,
  ColumnStore_type,
  ColumnStatusReportWarranty,
} from "../components/Table";
import DetailClaimScene from "../scene/Why/Claim/DetailClaimScene";
import EditClaimScene from "../scene/Why/Claim/EditClaimScene";
import CreateClaimScene from "../scene/Why/Claim/CreateClaimScene";
import HistoryClaimScene from "../scene/Why/Claim/HistoryClaimScene";
import reportWarrantyScene from "../scene/Why/Warranty/reportWarrantyScene";
import ProductsWarrantyScene from "../scene/Why/Management/ProductsWarrantyScene";

export const menu = {
  why: {
    defaultUrl: "/why/claim",
    menu: [
      {
        path: "/why/register-warranty",
        name: "ลงทะเบียนสินค้า",
        icon: "form",
        component: RegisterWarranty,
      },
      {
        path: "/why/claim",
        name: "เคลมสินค้า",
        icon: "file-search",
        component: ClaimScene,
      },
      {
        path: "/why/historyClaim",
        name: "ประวัติการเคลม",
        icon: "profile",
        component: HistoryClaimScene,
      },
      {
        path: "/why/reportWarranty",
        name: "รายงานการลงทะเบียน",
        icon: "profile",
        component: reportWarrantyScene,
      },
      {
        path: "/why/productsWarranty",
        name: "สินค้ารับประกัน",
        icon: "profile",
        component: ProductsWarrantyScene,
      },
    ],

    routes: [
      {
        index: 0,
        path: "/why/register-warranty",
        name: "ลงทะเบียนสินค้า",
        icon: "form",
        component: RegisterWarranty,
      },
      {
        index: 1,
        path: "/why/claim",
        name: "เคลมสินค้า",
        icon: "file-search",
        component: ClaimScene,
      },
      {
        path: "/why/claim_detail",
        name: "รายละเอียด",
        component: DetailClaimScene,
      },
      {
        path: "/why/billshipping",
      },
      {
        index: 2,
        path: "/why/historyClaim",
        name: "ประวัติการเคลม",
        component: HistoryClaimScene,
      },
      {
        index: 3,
        path: "/why/reportWarranty",
        name: "รายงานการลงทะเบียน",
        component: reportWarrantyScene,
      },
      {
        path: "/why/claim_edit",
        name: "แก้ไขรายการเคลมสินค้่า",
        component: EditClaimScene,
      },
      {
        path: "/why/claim_create",
        name: "ยืนเคลม",
        component: CreateClaimScene,
      },
      {
        index: 4,
        path: "/why/productsWarranty",
        name: "สินค้ารับประกัน",
        component: ProductsWarrantyScene,
      },
    ],
    title: "WHY maximize ME moment",
  },
};

export const columnTableClaim = (fn = () => { },
  onClick = () => { },
  onCreateMessage = () => { },
  onModalNote = () => {},
) => [
    {
      title: "ลำดับ",
      dataIndex: "no",
      align: "center",
      width: "80px",
      className: "td-content",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) => a.rowNo - b.rowNo,
    },
    {
      title: "เลขที่เคลม",
      dataIndex: "claim_id",
      align: "center",
      className: "td-content",
      width: "120px",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) =>
        a.claimNo < b.claimNo ? -1 : a.claimNo > b.claimNo ? 1 : 0,
    },
    {
      title: "Line Name",
      dataIndex: "line_name",
      align: "center",
      className: "td-content",
      width: "150px",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
    },
    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "name",
      align: "center",
      className: "td-content",
      width: "200px",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
    },
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "telephone",
      align: "center",
      className: "td-content",
      width: "150px",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
    },
    {
      title: "วันที่แจ้ง",
      dataIndex: "create_at",
      align: "center",
      width: "120px",
      className: "td-content",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) =>
        a.create_at < b.create_at ? -1 : a.create_at > b.create_at ? 1 : 0,
    },
    {
      title: "รหัสสินค้า",
      dataIndex: "productCode",
      align: "center",
      className: "td-content",
      width: "150px",
      onCell: (record, i) => ({
        onClick: () => fn(record),
        style: {
          whiteSpace: "wrap",
          maxWidth: "8vw",
        },
      }),

    },
    {
      title: "รุ่นสินค้า",
      dataIndex: "product_name",
      align: "center",
      className: "td-content",
      width: "300px",
      onCell: (record, i) => ({
        onClick: () => fn(record),
        style: {
          whiteSpace: "wrap",
          maxWidth: "8vw",
        },
      }),

    },
    {
      title: "เวลารับประกันคงเหลือ (วัน)",
      dataIndex: "time_warranty",
      align: "center",
      className: "td-content",
      width: "8vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) =>
        a.time_warranty < b.time_warranty
          ? -1
          : a.time_warranty > b.time_warranty
            ? 1
            : 0,
    },
    {
      title: "สถานะ",
      dataIndex: "claimStatus",
      align: "center",
      className: "td-content td-content-confirm",
      width: "8vw",
      render: (claimStatus) => <ColumnStatus status={claimStatus} />,
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      filters: [
        {
          text: "จัดส่งเรียบร้อย",
          value: 1,
        },
        {
          text: "รอตรวจสอบ",
          value: 2,
        },
        {
          text: "ดำเนินการ",
          value: 3,
        },
        {
          text: "ยกเลิกเคลม",
          value: 4,
        },
      ],
      onFilter: (value, record) => +record.claimStatus === +value,
    },
    {
      title: "Confirm EMS",
      dataIndex: "confirm_ems",
      align: "center",
      className: "td-content",
      width: "7vw",
      render: (text, record) =>
        text === "กรอก EMS" ? (
          <BtnConfirm title={text} onClick={() => onClick(record, 0)} />
        ) : text !== undefined && text !== "" && text !== null ? (
          <font onClick={() => onClick(record, 0)}>{text}</font>
        ) : (
          text
        ),
      onCell: (record, i) => ({
        onClick:
          record.shipping && record.shipping.ems
            ? () => onClick(record, 1)
            : null,
      }),
    },
    {
      title: "วันที่ส่งสินค้า",
      dataIndex: "shipping_date",
      align: "center",
      className: "td-content",
      width: "7.8vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) =>
        a.shipping_date < b.shipping_date
          ? -1
          : a.shipping_date > b.shipping_date
            ? 1
            : 0,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "note",
      align: "center",
      className: "td-content",
      width: "150px",
      render: (text, record) => 
      !text ? 
          <EditOutlined  
          style={{ fontSize: '1.5em'}}  
          onClick={() => onModalNote(record)} 
          />
      : 
      <p onClick={() => onModalNote(record)}  >
        {text}
      </p>    
    },
    {
      title: "Edit Product",
      dataIndex: "edit_product",
      align: "center",
      className: "td-content",
      width: "100px",
      fixed: "right",
    },
    {
      title: "ส่งข้อความ",
      width: "80px",
      align: "center",
      fixed: "right",
      render: (text, record) => <Icon
        type='message'
        style={{ fontSize: '1.5em' }}
        onClick={() => onCreateMessage(record)}
      />
    },

  ];
export const columnTableClaimFocus = (
  fn = () => { },
  onClick = () => { },
  onUactivate = () => { }
) => [
    {
      title: "ลำดับ",
      dataIndex: "no",
      align: "center",
      width: "3vw",
      className: "td-content",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "เลขที่เคลม",
      dataIndex: "no_claim",
      align: "center",
      className: "td-content",
      width: "10vh",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) =>
        a.no_claim < b.no_claim ? -1 : a.no_claim > b.no_claim ? 1 : 0,
    },

    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "name",
      align: "center",
      className: "td-content",
      width: "9vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
    },
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "tel",
      align: "center",
      className: "td-content",
      width: "9vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
    },
    {
      title: "วันที่แจ้ง",
      dataIndex: "dateclaim_formate",
      align: "center",
      width: "5vw",
      className: "td-content",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) =>
        a.dateclaim < b.dateclaim ? -1 : a.dateclaim > b.dateclaim ? 1 : 0,
    },
    {
      title: "รุ่นสินค้า",
      dataIndex: "nameproduct",
      align: "center",
      className: "td-content",
      width: "8vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
        style: {
          whiteSpace: "wrap",
          maxWidth: "8vw",
        },
      }),
      render: (text) => {
        return (
          <div style={{ fontSizeAdjust: 1 }}>
            <font className={"font-table"}>{text}</font>
          </div>
        );
      },
    },
    {
      title: "เวลารับประกันคงเหลือ (วัน)",
      dataIndex: "time_warranty",
      align: "center",
      className: "td-content",
      width: "8vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) =>
        a.time_warranty < b.time_warranty
          ? -1
          : a.time_warranty > b.time_warranty
            ? 1
            : 0,
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      align: "center",
      className: "td-content td-content-confirm",
      width: "8vw",
      render: (status, record) => (
        <ColumnStatus_FOCUS status={status} type={record.type} />
      ),
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      filters: [
        {
          text: "รอชำระเงิน",
          value: 1,
        },
        {
          text: "รอการตรวจสอบ",
          value: 2,
        },
        {
          text: "ชำระเงินสำเร็จ",
          value: 3,
        },
        {
          text: "จัดส่งเรียบร้อย",
          value: 4,
        },
        {
          text: "ไม่อนุมัติ",
          value: 99,
        },
      ],
      onFilter: (value, record) => +record.status === +value,
    },
    {
      title: "ประเภทการเคลม",
      dataIndex: "type",
      align: "center",
      className: "td-content td-content-confirm",
      width: "8vw",
      render: (type) => <ColumnStore_type type={type} />,
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      filters: [
        {
          text: "DPLUS",
          value: "COMPANY",
        },
        {
          text: "STORE",
          value: "STORE",
        },
      ],
      onFilter: (value, record) => record.type === value,
    },
    {
      title: "Confirm EMS",
      dataIndex: "confirm_ems",
      align: "center",
      className: "td-content",
      width: "7vw",
      render: (text, record) =>
        record.type === "STORE" ? (
          ""
        ) : text === "กรอก EMS" ? (
          <BtnConfirm title={text} onClick={() => onClick(record, 0)} />
        ) : (
          text
        ),

      onCell: (record, i) => ({
        onClick: record.tracking ? () => onClick(record, 1) : null,
      }),
    },
    {
      title: "วันที่ส่งสินค้า",
      dataIndex: "shipping_formate",
      align: "center",
      className: "td-content",
      width: "7.8vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) =>
        a.shipping_date < b.shipping_date
          ? -1
          : a.shipping_date > b.shipping_date
            ? 1
            : 0,
    },
    {
      title: "ปลดรหัสประกัน",
      dataIndex: "unactive",
      align: "center",
      className: "td-content",
      width: "7vw",
      render: (text, record) =>
        text === "รหัสประกัน" ? (
          <BtnConfirm title={text} onClick={() => onUactivate(record, 0)} />
        ) : (
          text
        ),
      onCell: (record, i) => ({
        onClick: record.unactive_code ? () => onUactivate(record, 1) : null,
      }),
    },
    {
      title: "เคลม Dealer",
      dataIndex: "document_id",
      align: "center",
      className: "td-content",
      width: "7vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
    },

    // {
    //     title: 'หมายเหตุ',
    //     dataIndex: 'note',
    //     align: 'center',
    //     className: 'td-content',
    //     onCell: (record, i) => ({
    //         onClick: () => fn(record)
    //     }),
    //     render: note => <font style={{ fontSize: '0.6vw' }}>{note}</font>
    // },
  ];

export const columnTableProductsWarranty = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    width: "8%",
    className: "td-content",
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: "รูปสินค้า",
    dataIndex: "productImage",
    align: "center",
    width: "12%",
    className: "td-content",
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "productName",
    align: "center",
    width: "25%",
    className: "td-content",
  },
  {
    title: "ประเภทสินค้า",
    dataIndex: "productTypeNameTH",
    align: "center",
    width: "10%",
    className: "td-content",
  },
  {
    title: "บาร์โค้ด",
    dataIndex: "barcode",
    align: "center",
    width: "15%",
    className: "td-content",
  },

  {
    title: "สถานะ",
    dataIndex: "productStatusName",
    align: "center",
    width: "10%",
    className: "td-content",
  },
  {
    title: "แก้ไข",
    dataIndex: "productEdit",
    align: "center",
    width: "5%",
    className: "td-content",
    // render: (text, record) => (
    //   // <Button>
    //   <EditOutlined
    //     style={{ fontSize: "22px", color: "#faad14" }}
    //     onClick={this.setState({ openEdit: true })}
    //   />
    //   // แก้ไข
    //   // </Button>
    // ),
  },
];

export const columnTableAccounting = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    width: "3vw",
    className: "td-content",
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: "วันที่โอน",
    dataIndex: "date_pay",
    align: "center",
    width: "5vw",
    className: "td-content",
    sorter: (a, b) =>
      a.dateclaim < b.dateclaim ? -1 : a.dateclaim > b.dateclaim ? 1 : 0,
  },
  {
    title: "เลขที่เคลม",
    dataIndex: "no_claim",
    align: "center",
    className: "td-content",
    width: "10vh",
  },

  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "เบอร์โทรศัพท์",
    dataIndex: "tel",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "จำนวนเงิน",
    dataIndex: "money_pay",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "item_code",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
];
export const columnTableImportAx = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    width: "3vw",
    className: "td-content",
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: "วันที่แจ้งเคลม",
    dataIndex: "dateclaim_formate",
    align: "center",
    width: "5vw",
    className: "td-content",
    sorter: (a, b) =>
      a.dateclaim < b.dateclaim ? -1 : a.dateclaim > b.dateclaim ? 1 : 0,
  },
  {
    title: "วันที่ส่งสินค้า",
    dataIndex: "shipping_formate",
    align: "center",
    width: "5vw",
    className: "td-content",
    sorter: (a, b) =>
      a.dateclaim < b.dateclaim ? -1 : a.dateclaim > b.dateclaim ? 1 : 0,
  },
  {
    title: "เลขที่เคลม",
    dataIndex: "no_claim",
    align: "center",
    className: "td-content",
    width: "10vh",
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "nameproduct",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "ยี่ห้อมือถือ",
    dataIndex: "brandmobile",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "รุ่นมือถือ",
    dataIndex: "modelmobile",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "item_code",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "เลขเอกสารจาก AX",
    dataIndex: "import_doc",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "สถานะการ Import",
    dataIndex: "import_status",
    align: "center",
    className: "td-content td-content-confirm",
    width: "8vw",
    render: (import_status) => (
      <ColumnStatus_ImportAx_Focus status={import_status} />
    ),

    filters: [
      {
        text: "รอการดำเนินการ",
        value: 99,
      },
      {
        text: "Importสำเร็จ",
        value: 1,
      },
      {
        text: "Importไม่สำเร็จ",
        value: 2,
      },
    ],
    onFilter: (value, record) => +record.import_status === +value,
  },
];

export const columnTableReport = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },
  {
    title: "รายการสินค้า",
    dataIndex: "product_name",
    align: "center",
    width: "30%",
  },
  {
    title: "รูปสินค้า",
    dataIndex: "product_image",
    align: "center",
    render: (url) => (
      <img src={url} style={{ width: "2cm", height: "3cm" }} alt={""} />
    ),
  },
  {
    title: "จำนวน",
    dataIndex: "count",
    align: "center",
  },
];
export const columnTableReportFocus = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },
  {
    title: "รายการสินค้า",
    dataIndex: "product_name",
    align: "center",
    width: "30%",
  },
  {
    title: "รูปสินค้า",
    dataIndex: "product_image",
    align: "center",
    render: (url) => (
      <img src={url} style={{ width: "2cm", height: "3cm" }} alt={""} />
    ),
  },
  {
    title: "จำนวน",
    dataIndex: "count",
    align: "center",
  },
];

export const columnTableHistoryClaim = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },
  {
    title: "Line Name",
    dataIndex: "line_name",
    align: "center",
    // width: '30%'
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "เบอร์โทร",
    dataIndex: "telephone",
    align: "center",
  },
  // {
  //     title: 'รหัสสินค้าลงทะเบียน',
  //     dataIndex: 'product_register',
  //     align: 'center',
  //     sorter: (a, b) => a.product_register < b.product_register ? -1 : a.product_register > b.product_register ? 1 : 0
  // },
  // {
  //     title: 'ชื่อสินค้า',
  //     dataIndex: 'product_name',
  //     align: 'center',
  // },
  {
    title: "จำนวนการเคลม",
    dataIndex: "count",
    align: "center",
    sorter: (a, b) => (a.count < b.count ? -1 : a.count > b.count ? 1 : 0),
  },
  {
    title: "วันที่เคลมล่าสุด",
    dataIndex: "last_date",
    align: "center",
    sorter: (a, b) =>
      a.last_date < b.last_date ? -1 : a.last_date > b.last_date ? 1 : 0,
  },
];

export const FocuscolumnTableHistory = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },

  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "เบอร์โทร",
    dataIndex: "telephone",
    align: "center",
  },

  {
    title: "จำนวนการเคลม",
    dataIndex: "count",
    align: "center",
    sorter: (a, b) => (a.count < b.count ? -1 : a.count > b.count ? 1 : 0),
  },
  {
    title: "วันที่เคลมล่าสุด",
    dataIndex: "last_date",
    align: "center",
    sorter: (a, b) =>
      a.last_date < b.last_date ? -1 : a.last_date > b.last_date ? 1 : 0,
  },
];
export const columnTableReportWarranty = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },
  {
    title: "เลขที่ประกัน",
    dataIndex: "warrantyNo",
    align: "center",
    // width: '30%'
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "customerName",
    align: "center",
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "productName",
    align: "center",
  },
  {
    title: "แบรนด์",
    dataIndex: "brandMobileName",
    align: "center",
  },
  {
    title: "รุ่น",
    dataIndex: "modelMobileName",
    align: "center",
  },
  {
    title: "สถานะ",
    dataIndex: "reportWarrantyStatus",
    align: "center",
    className: "td-content td-content-confirm",
    width: "8vw",
    render: (reportWarrantyStatus) => (
      <ColumnStatusReportWarranty status={reportWarrantyStatus} />
    ),
    // onCell: (record, i) => ({
    //     onClick: () => fn(record)
    // }),
    filters: [
      {
        text: "ลงทะเบียน",
        value: 0,
      },
      {
        text: "จัดส่งเรียบร้อย",
        value: 1,
      },
      {
        text: "รอตรวจสอบ",
        value: 2,
      },
      {
        text: "ดำเนินการ",
        value: 3,
      },
      {
        text: "ยกเลิกเคลม",
        value: 4,
      },
    ],
    onFilter: (value, record) => +record.reportWarrantyStatus === +value,
  },

  // {
  //     title: 'รหัสสินค้าลงทะเบียน',
  //     dataIndex: 'product_register',
  //     align: 'center',
  //     sorter: (a, b) => a.product_register < b.product_register ? -1 : a.product_register > b.product_register ? 1 : 0
  // },
  // {
  //     title: 'ชื่อสินค้า',
  //     dataIndex: 'product_name',
  //     align: 'center',
  // },
  // {
  //     title: 'จำนวนการเคลม',
  //     dataIndex: 'count',
  //     align: 'center',
  //     sorter: (a, b) => a.count < b.count ? -1 : a.count > b.count ? 1 : 0
  // },

  {
    title: "เบอร์มือถือ",
    dataIndex: "customerTelephone",
    align: "center",
  },
  {
    title: "วันที่ลงทะเบียน",
    dataIndex: "warrantyDate",
    align: "center",
    sorter: (a, b) =>
      a.warrantyDate < b.warrantyDate
        ? -1
        : a.warrantyDate > b.warrantyDate
          ? 1
          : 0,
  },
];

export const FocuscolumnTableWarranty = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },

  {
    title: "เลขที่ประกัน",
    dataIndex: "no_warranty",
    align: "center",
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "nameproduct",
    align: "center",
  },
  {
    title: "แบรน",
    dataIndex: "brandmobile",
    align: "center",
  },
  {
    title: "รุ่น",
    dataIndex: "modelmobile",
    align: "center",
  },
  {
    title: "สถานะ",
    dataIndex: "status",
    align: "center",
    className: "td-content td-content-confirm",
    width: "8vw",
    render: (status, record) => (
      <ColumnStatus_FOCUS status={status} type={"COMPANY"} />
    ),

    filters: [
      {
        text: "ลงทะเบียน",
        value: 0,
      },
      {
        text: "รอชำระเงิน",
        value: 1,
      },
      {
        text: "รอการตรวจสอบ",
        value: 2,
      },
      {
        text: "ชำระเงินสำเร็จ",
        value: 3,
      },
      {
        text: "จัดส่งเรียบร้อย",
        value: 4,
      },
      {
        text: "ไม่อนุมัติ",
        value: 99,
      },
    ],
    onFilter: (value, record) => +record.status === +value,
  },

  {
    title: "เบอร์โทร",
    dataIndex: "telephone",
    align: "center",
  },
  {
    title: "วันที่ลงทะเบียน",
    dataIndex: "create",
    align: "center",
    sorter: (a, b) => (a.create < b.create ? -1 : a.create > b.create ? 1 : 0),
  },
];

export const KEY_BILL = "KqkTxGXzw1";

export const STATUS_CLAIM = [
  {
    status_id: 1,
    status_name: "จัดส่งเรียบร้อย",
  },
  {
    status_id: 2,
    status_name: "รอตรวจสอบ",
  },
  {
    status_id: 3,
    status_name: "ดำเนินการ",
  },
  {
    status_id: 4,
    status_name: "ยกเลิกเคลม",
  },

  // {
  //     status_id: 0,
  //     status_name: 'รอรับเรื่อง'
  // }, {
  //     status_id: 1,
  //     status_name: 'กำลังดำเนินการ'
  // }, {
  //     status_id: 2,
  //     status_name: 'จัดส่งเรียบร้อย'
  // }, {
  //     status_id: 99,
  //     status_name: 'ไม่อนุมัติ'
  // }
];
export const STATUS_CLAIM_FOCUS = [
  {
    status_id: 0,
    status_name: "ลงทะเบียน",
  },
  {
    status_id: 1,
    status_name: "รอชำระเงิน",
  },
  {
    status_id: 2,
    status_name: "รอการตรวจสอบ",
  },
  {
    status_id: 3,
    status_name: "ชำระเงินเรียบร้อย",
  },
  {
    status_id: 4,
    status_name: "จัดส่งเรียบร้อย",
  },
  {
    status_id: 99,
    status_name: "ไม่อนุมัติ",
  },
];
export const STATUS_CLAIM_DEALER = [
  {
    status_id: 2,
    status_name: "รอรับบริการ",
  },
  {
    status_id: 3,
    status_name: "เคลมเสร็จเรียบร้อย",
  },
  {},
];
export const STORE_FOCUS = [
  {
    original_name: "COMPANY",
    thai_name: "DPLUS",
  },
  {
    original_name: "STORE",
    thai_name: "STORE",
  },
  {
    original_name: "",
    thai_name: "",
  },
];
export const STATUS_REPORT_REGISTER = [
  {
    status_id: 0,
    status_name: "ลงทะเบียน",
  },
  {
    status_id: 1,
    status_name: "จัดส่งเรียบร้อย",
  },
  {
    status_id: 2,
    status_name: "รอตรวจสอบ",
  },
  {
    status_id: 3,
    status_name: "ดำเนินการ",
  },
  {
    status_id: 4,
    status_name: "ยกเลิกเคลม",
  },
];
export const STATUS_IMPORTAX_FOCUS = [
  {
    status_id: 99,
    status_name: "รอการดำเนินการ",
  },
  {
    status_id: 1,
    status_name: "Importเข้าเรียบร้อย",
  },
  {
    status_id: 2,
    status_name: "Importไม่สำเร็จ",
  },
];

export const STATUS_STORE_CLAIM = [
  {
    status_id: 0,
    status_name: "COMPANY",
  },
  {
    status_id: 1,
    status_name: "STORE",
  },
  {
    status_id: 2,
    status_name: "",
  },
];

export const FORM_EDIT_PRODUCT = [
  {
    label: "Barcode",
    type: "SEARCH",
  },
  {
    label: "ชื่อสินค้า",
    type: "TEXT_NAME",
  },
  {
    label: "ประเภทสินค้า",
    type: "TEXT_TYPE",
  },
  {
    label: "อาการเสีย",
    type: "DROPDOWN_BREAK",
  },
  {
    label: "ภาพสินค้า",
    type: "IMAGE",
  },
];

export const FORM_ADD_PRODUCT_WARRANTY = [
  {
    label: "บาร์โค้ดสินค้า",
    type: "text",
    name: "barcode",
    value: "",
    required: true,
  },

  {
    label: "ชื่อสินค้า",
    name: "productName",
    type: "text",
    value: "",
    required: true,
  },
  {
    label: "รหัสสินค้า",
    name: "productCode",
    type: "text",
    value: "",
    required: true,
  },
  {
    label: "ประเภทสินค้า",
    type: "select",
    name: "productTypeID",
    value: 1,
    required: true,
  },

  {
    label: "สีสินค้า",
    type: "text",
    name: "colors",
    value: "",
    required: false,
  },
  {
    label: "ProductList",
    type: "text",
    name: "productList",
    value: "",
    required: false,
  },
  {
    label: "สถานะการใช้งาน",
    type: "switch",
    name: "productStatus",
    value: true,
    required: false,
  },
  {
    label: "อัพโหลดรูปสินค้า",
    type: "upload",
    name: "productImage",
    value: [],
    required: false,
  },
];

export const FORM_EDIT_PRODUCT_WARRANTY = [
  {
    label: "บาร์โค้ดสินค้า",
    type: "text",
    name: "barcode",
    value: "",
    required: true,
  },
  {
    label: "",
    name: "productID",
    type: "hidden",
    value: "",
    required: false,
  },

  {
    label: "ชื่อสินค้า",
    name: "productName",
    type: "text",
    value: "",
    required: true,
  },
  {
    label: "รหัสสินค้า",
    name: "productCode",
    type: "text",
    value: "",
    required: true,
  },
  {
    label: "ประเภทสินค้า",
    type: "select",
    name: "productTypeID",
    value: "",
    required: true,
  },

  {
    label: "สีสินค้า",
    type: "text",
    name: "colors",
    value: "",
    required: false,
  },
  {
    label: "ProductList",
    type: "text",
    name: "productList",
    value: "",
    required: false,
  },
  {
    label: "สถานะการใช้งาน",
    type: "switch",
    name: "productStatus",
    value: true,
    required: false,
  },
  {
    label: "อัพโหลดรูปสินค้า",
    type: "upload",
    name: "productImage",
    value: [],
    required: false,
  },
];

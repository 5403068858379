import { create } from "apisauce";

// export const ip = 'https://www.service-frontd.com:4500/api/v1'

// export const ip = "https://dplus-service.com:1170/api/v1";
export const ip = "https://why.dplus-frontb.com:2083/api/v1";
// export const ip = "http://localhost:1170/api/v1";

//export const ip = 'http://192.168.1.102:4500/api/v1'
//export const ip = 'http://192.168.1.121:4500/api/v1'

export const VERSION = 'V.2.1.0 #2024-04-08'


const api = create({
  baseURL: ip,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000, // 10 seconds
});

const apiupload = create({
  baseURL: ip,
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
  },
  timeout: 30000, // 10 seconds
});

const apiapp = create({
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
  },
  timeout: 30000, // 10 seconds
});

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const POST = (path, obj) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const GET = (path) =>
  new Promise((resolve, reject) => {
    api
      .get(path)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const UPLOAD = (path, formdata) =>
  new Promise((resolve, reject) => {
    apiupload
      .post(path, formdata)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const CHECK = () => {
  new Promise((resolve, reject) => {
    api
      .post()
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });
};

export const GETAPP = (path) =>
  new Promise((resolve, reject) => {
    apiapp
      .get(path)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/* ################################################## URL ################################################## */
//
// export const LOGIN = '/auth/login';
// export const RIZZ_GETCLAIM = '/rizz/claim/get';
// export const RIZZ_GETREPORT = '/rizz/report/get';
// export const RIZZ_INSERT_EMS = '/rizz/claim/insert_ems';
// export const RIZZ_GETHISTORY = '/rizz/claim/claim_history'
// export const RIZZ_DETAILCLAIMBYID = '/rizz/claim/detailclaimById'
// export const RIZZ_UPDATESTATUS = '/rizz/claim/updateStatus'
// export const RIZZ_UPSERTCLAIM = '/rizz/claim/updateclaimById'
// export const RIZZ_GETUSERALL = '/auth/getUserAll'
// export const RIZZ_REGIS_WARRANTY = '/rizz/registor/regiswarranty'
// export const RIZZ_GETUSERID = '/rizz/claim/getUserById'
// export const RIZZ_EDITCLAIMBYID = '/rizz/claim/editClaim'

export const LOGIN = "/auth/login";
export const RIZZ_GETCLAIM = "/claim/getListClaimAdmin";
export const RIZZ_GETDETIALBYBARCODE = "/product/getProductByBarcode/";
export const RIZZ_UPDATEPRODUCTBYCLAIMNO = "/claim/updateProductClaim";
export const RIZZ_INSERT_EMS = "/claim/updateTracking";
export const RIZZ_GETHISTORY = "/claim/getClaimHistory";
export const RIZZ_UPDATESTATUS = "/claim/updateApproveClaim";
export const RIZZ_SEND_EMS = "/system/sendMessageTracking";
export const RIZZ_GET_REPORT_WARRANTY = "/report/getReportWarranty";
export const RIZZ_GET_BRANDMOBILE = "/option/getBrandMobile";
export const RIZZ_GET_MODELMOBILE = "/option/getModelByBrand/";
export const RIZZ_REGIS_WARRANTY = "/warranty/insertWarranty";
export const RIZZ_GET_DETAIL_BY_TEL = "/warranty/getWarrantyByTel";
export const RIZZ_GET_OPTION_BREAK = "/option/getBreakdownByProductType/";
export const RIZZ_CLAIM_PRODUCT = "/claim/insertclaim";
export const RIZZ_UPDATECLAIM = "/claim/updateClaim";
export const RIZZ_GET_DETIAL_BY_CLAIMNO = "/claim/getClaimDetailByNo/";
export const RIZZ_EXPORT_REPORT = "/export/exportWarranty";
export const RIZZ_EXPORT_CLAIM = "/export/exportClaim";

export const RIZZ_GETREPORT = "/why/report/get";

export const RIZZ_DETAILCLAIMBYID = "/why/claim/detailclaimById";

export const RIZZ_UPSERTCLAIM = "/why/claim/updateclaimById";
// export const RIZZ_GETUSERALL = '/auth/getUserAll'

export const RIZZ_GETUSERID = "/why/claim/getUserById";
export const RIZZ_EDITCLAIMBYID = "/why/claim/editClaim";

//////////// API WHY ///////////////////////////
export const WHY_GET_PRODUCTS_WARRANTY = "/product/getProducts";
export const WHY_GET_PRODUCTS_TYPE = "/product/getProductType";
export const WHY_INSERT_PRODUCT = "/product/insertProduct";
export const WHY_UPDATE_PRODUCT = "/product/updateProduct";


//SERVICE
export const SEND_MESSAGE_LINE = `/system/send-message-line`;
export const UPDATE_CLAIM_NOTE = `/claim/edit-note`;

